<template>
  <div class="sticky">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <img
          :src="logo"
          alt="logo"
          id="logo"
          onclick="window.location.href='/'"
        />
        <button
          style="background-color: #f38d26"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="/">Home</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About
              </a>
              <ul class="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <a class="dropdown-item" href="/about-us">About Us</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/partners">Partners</a>
                </li>
                <li><a class="dropdown-item" href="#">Our Team</a></li>
                <li>
                  <a class="dropdown-item" href="/leadership">Leadership </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Clients Testimonials</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="servicesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <a class="dropdown-item" href="/oracleConsulting"
                    >Oracle Consulting</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/oracleImplementation"
                    >Oracle Implementation</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/managedServices"
                    >Managed Services</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/customAplications"
                    >Custom Applications</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="productsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </a>
              <ul class="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <a class="dropdown-item" href="/gst">GST</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/vendorPortal">Vendor Portal</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/cms"
                    >Collection Management System</a
                  >
                </li>
                <li><a class="dropdown-item" href="#">Bank Integration</a></li>
                <li
                  @mouseenter="openDropdown"
                  @mouseleave="closeDropdown"
                  ref="dropdownItem"
                >
                  <a class="dropdown-item"  href="#">Real Estate</a>
                </li>
                <!-- <div class="dropdown">
                  <div
                    class="dropdown-menu dropdown-menu-start"
                    :class="{ show: isDropdownOpen }"
                  >
                    <ul class="no-bullets" aria-labelledby="servicesDropdown">
                      <li>
                        <a class="dropdown-item" href="#">Leasing</a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          >Facility Management System</a
                        >
                      </li>
                    </ul>
                  </div>
                </div> -->
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Industries
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href="/banking">Banking</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/financial"
                    >Financial Services & Insurance</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/manufacturing"
                    >Manufacturing</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/realEstate"
                    >Infrastructure & Real State</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="/logistics">Logistics</a>
                </li>
                <li><a class="dropdown-item" href="/telecom">Telecom</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/careers" tabindex="-1">Careers</a>
            </li>
            <li class="contact">
              <a class="nav-link" href="/contact" tabindex="-1"
                ><i
                  class="bi bi-telephone"
                  style="font-size: 16px; margin-right: 6px"
                ></i
                >Contact Us</a
              >
            </li>
            <img :src="oracle" alt="oraclePartner" class="oraclePartner" />
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script src="./navbar.js"></script>
<style src="./navbar.css"></style>



