<template>
  <div class="manufacturingThird">
    <h1 class="manufacturingThirdHeading">Oracle E-Business Suite (EBS) Process Manufacturing</h1>
    <div class="gstSecond">
      <p>
        <span style="font-size: 22px; font-weight: bold; color: #f38d26"
          >Oracle EBS</span
        >
        is a suite of integrated applications that help businesses manage
        various aspects of their operations. Within EBS, the
        <span style="font-size: 22px; font-weight: bold; color: #f38d26">
          Process Manufacturing
        </span>
        module is designed specifically for industries that involve batch
        processing, such as
        <span style="font-size: 22px; font-weight: bold; color: #f38d26">
          chemicals, pharmaceuticals, and food and beverages</span
        >. Key features of Oracle EBS Process Manufacturing include:
      </p>
    </div>
    <div class="manufacturingThirdTwo">
      <div class="managedServicesFourthFirst">
        <div>
          <div>
            <h1>{{ heading }}</h1>
            {{ textContent }}
          </div>
        </div>
        <div>
          <div
          :class="heading === 'Recipe and Formula Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('odm')"
          >
          Recipe and Formula Management
          </div>
          <div
          :class="heading === 'Batch Processing' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('oas')"
          >
          Batch Processing
          </div>
          <div
          :class="heading === 'Quality Control' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('ocm')"
          >
          Quality Control
          </div>
          <div
          :class="heading === 'Lot and Serial Tracking' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('pto')"
          >
          Lot and Serial Tracking
          </div>
          <div
          :class="heading === 'Inventory Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('sac')"
          >
          Inventory Management
          </div>
          <div
          :class="heading === 'Regulatory Compliance' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('rc')"
          >
          Regulatory Compliance
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
