<template>
    <div>
        <first />
        <second />
        <third />
        <fourth />
        <fifth />
    </div>
</template>

<script src="./cms.js"></script>
<style src="./cms.css"></style>