<template>
  <div>
    <p class="contactUsSecondText">
      We're thrilled to hear from you! Whether you have a project in mind, need
      expert advice, or just want to learn more about our services, our team is
      here to assist you. Feel free to reach out using the information below, or
      use the contact form to send us a message. We look forward to connecting
      with you.
    </p>
    <div class="contactUsThird">
      <div class="contactUsThirdForm">
        <div>
          <label for="form">Full Name</label>
          <br />
          <input type="text" placeholder="Enter your Full Name" />
          <br />
          <label for="form">Email</label>
          <br />
          <input type="text" placeholder="Enter your Email" />
          <br />
          <label for="form">Contact Number</label>
          <br />
          <input type="text" placeholder="Enter your Contact Number" />
          <br />
          <label for="form">Company</label>
          <br />
          <input type="text" placeholder="Enter your Company Name" />
          <br />
          <label for="form">Message</label>
          <br />
          <textarea name="form" id="form" cols="30" rows="3"></textarea>
          <br />
          <button>Submit</button>
        </div>
      </div>
      <div v-if="isLaptop" class="contactUsFormImage">
        <img src="../../../../assets/contactUs/contactUsFormImage.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
