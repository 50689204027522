export default {
    data() {
      return {
        screenWidth: window.innerWidth
      };
    },
    computed: {
      isLaptop() {
        return this.screenWidth > 868;
      }
    },
    created() {
      window.addEventListener('resize', this.updateScreenWidth);
    },
    methods: {
      updateScreenWidth() {
        this.screenWidth = window.innerWidth;
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateScreenWidth);
    }
  };