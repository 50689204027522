<template>
  <div class="profileContainer">
      <div class="col">
        <div class="text-content">
          <div id="firstText">
            <p>
              Unlocking the power of Oracle ERP: Empowering businesses,
              transforming futures.
            </p>
          </div>
          <div id="secondText">
            <p>
              At CCS, we are dedicated to unleashing the power of Oracle ERP
              (Enterprise Resource Planning). With our expertise and
              extensive knowledge of Oracle ERP, we empower businesses to
              optimize their operations, drive growth, and transform their
              futures.
            </p>
          </div>
        </div>
      </div>
      <div class="colImages">
        <div class="">
          <div class="image-content">
            <img
              src="../../../../assets/CCSgif.gif"
              alt="profile"
              id="profile"
            />
          </div>
            <img src="../../../../assets/cross.png" alt="slogan" class="slogan">
        </div>
      </div>
  </div>
</template>

<script src="./profile.js"></script>
<style src="./profile.css"></style>
