<template>
  <div class="gstFourth">
    <h1>Key Features of GST Solutions</h1>
    <div class="gstFourthContainer">
      <div>
        <h1>Integration of Purchase Register from Oracle to RealApp</h1>
        <p>
          We have both the feature either Integration or Manual Import, we will
          directly integrate Oracle with the Application, and there is no need
          for manual data uploading every time.
        </p>
      </div>
      <div>
        <h1>Sanity checking of Invoices booked by the Team</h1>
        <p>
          Before the period closure, the business will know the booking error
          and get time to correct the booking before the closure, maximizing the
          reconciliation input.
        </p>
      </div>
      <div>
        <h1>
          Directly download GSTR2A/2B/
          GSTR1/GSTR3B from the portal by RealApp
        </h1>
        <p>
          No need to login for GSTN and save the Excel, The Tool will directly
          download the GSTR2A/2B/GSTR1/GSTR3B through API and also maintains the
          records in the Database.
        </p>
      </div>
      <div>
        <h1>Maximum Reconciliation by tool</h1>
        <p>
          The tool will Auto Reconciled the Purchase Register with GSTR2B Near
          about 80-90%
        </p>
      </div>
      <div>
        <h1>Suggested Match Report</h1>
        <p>
          The Tool will also share the Suggested Match Report for further
          Reconciliation.
        </p>
      </div>
      <div>
        <h1>Manual Reconciliation</h1>
        <p>
          Functionality of Manual Reconciliation of GSTR2B with Purchase
          Register.
        </p>
      </div>
      <div>
        <h1>
          Filling the communication gap between the company and vendor by Email
        </h1>
        <p>Auto Emailer of Unreconciled Invoices.</p>
      </div>
      <div>
        <h1>Compliance Dashboards for company and vendor performance</h1>
        <p>
          Several Dashboards to examine the business and Vendor compliance
          status.
        </p>
      </div>
      <div>
        <h1>Verification of Vendor GSTN and e-filing</h1>
        <p>
          The tool will check the Vendor GSTN active/ inactive
          /Cancelled/Suspended status and also check the E-filing Details.
        </p>
      </div>
      <div>
        <h1>Detection of Compliance Fraud</h1>
        <p>
          The Business will know the compliance fraud and, on an action, we can
          stop the booking of invoices in GST head.
        </p>
      </div>
      <div>
        <h1>Payment hold and un-hold status within Oracle</h1>
        <p>Functionality of Hold/ Unhold Invoices Payment.</p>
      </div>
      <div>
        <h1>Full Audit trail</h1>
        <p>Full Audit Trail of Input</p>
      </div>
      <div>
        <h1>Forego /reclaim</h1>
        <p>Forego/ Reclaim Reports</p>
      </div>
      <div>
        <h1>Full GST Solution</h1>
        <p>GSTR1 filing, 2A/2B reconciliation, and GSTR 3B Filing</p>
      </div>
      <div>
        <h1>Vendor portal</h1>
        <p>
          (IRN Applicability check, Pan No, GST No, Bank acct along with docs)
        </p>
      </div>
      <div>
        <h1>Security</h1>
        <p>
          The application will be stored on your server only, No external server
          is required to share the Business information
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
