<template>
  <div class="about" :class="{ active: animate }">
      <div class="aboutHeader">
        <img
          src="../../../../assets/contactUs/contactUsFirst.jpg"
          alt="aboutHeader"
          id="aboutHeaderImage"
        />
      </div>
      <div class="top-left">
        <h1 class="drop-from-top">
          Contact Us
        </h1>
        <p class="drop-from-bottom">
          Connect with Us: Your Questions, Our Expertise, Seamless Solutions.
        </p>
      </div>
    </div>
</template>

<script src="./first.js"></script>
<style src="./first.css"></style>
