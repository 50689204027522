<template>
    <div class="managedServicesFourth">
      <div class="managedServicesFourthFirst">
        <div>
            <div>
                <h1>{{ heading }}</h1>
                {{ textContent }}</div>
        </div>
        <div>
            <div :class="selectedTab === 'Custom Web Applications' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('cwa')">Custom Web Applications</div>
            <div :class="selectedTab === 'E-Commerce Solutions' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('ecs')">E-Commerce Solutions</div>
            <div :class="selectedTab === 'Content Management Systems (CMS' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('cms')">Content Management Systems (CMS)</div>
            <div :class="selectedTab === 'API Development and Integration' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('adi')">API Development and Integration</div>
            <div :class="selectedTab === 'Responsive Web Design' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('rwd')">Responsive Web Design</div>
        </div>
      </div>
    </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
