<template>
  <div id="whatWeDoContainer">
    <h1 id="whatWeDo">What We Do</h1>
    <div v-if="isLaptop" id="tabcontainer">
      <nav id="contents">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Oracle Consulting
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Oracle Implementation
          </button>
          <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Managed Services
          </button>
          <button
            class="nav-link"
            id="nav-contac-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contac"
            type="button"
            role="tab"
            aria-controls="nav-contac"
            aria-selected="false"
          >
            Custom Applications
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div class="grid">
            <div class="textcontent wwd-observe-me">
              <div id="textwhatWeDo">
                <p id="insidetextwhatWeDo">
                Our Oracle consulting services are aimed at helping businesses
                optimize their use of Oracle products and maximize their return
                on investment. Our services are delivered by highly skilled
                consultants who possess in-depth knowledge and expertise in
                Oracle EBS, Oracle Fusion, and custom solutions. Our consultants
                work closely with clients to understand their unique
                requirements, provide strategic advice, and implement Oracle
                solutions tailored to their specific needs.
              </p>
              </div>
            </div>
            <div id="imagecontent">
              <img src="../../../../assets/homeWeDo/consult.png" alt="" id="laptopimage" />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div class="grid">
            <div class="textcontent wwd-observe-me">
              <div id="textwhatWeDo">
                <p id="insidetextwhatWeDo">
                Our implementations often involve integrating the Oracle
                solution with other enterprise systems, such as ERP systems, CRM
                platforms, or data warehouses. Our consultants design and
                implement interfaces and integration mechanisms to enable
                seamless data flow between systems.
              </p>
              </div>
            </div>
            <div id="imagecontent">
              <img src="../../../../assets/homeWeDo/implement.png" alt="" id="laptopimage" />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div class="grid">
            <div class="textcontent wwd-observe-me">
              <div id="textwhatWeDo">
                <p id="insidetextwhatWeDo">
                Our Oracle Managed Services consultants are highly skilled
                professionals with in-depth knowledge of Oracle's vast product
                portfolio, including but not limited to Oracle Database, Oracle
                Cloud Applications, Oracle E-Business Suite, Oracle Fusion
                Middleware, and more. <br />
                They comprehensively understand how these solutions can be
                tailored to meet specific business needs and challenges.
              </p>
              </div>
            </div>
            <div id="imagecontent">
              <img src="../../../../assets/homeWeDo/managed.png" alt="" id="laptopimage" />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-contac"
          role="tabpanel"
          aria-labelledby="nav-contac-tab"
        >
          <div class="grid">
            <div class="textcontent wwd-observe-me">
              <div id="textwhatWeDo">
                <p id="insidetextwhatWeDo">
                Our applications are designed to meet specific business
                requirements and are developed from scratch, taking into account
                the unique needs and objectives of the organization.
              </p>
              </div>
            </div>
            <div id="imagecontent">
              <img src="../../../../assets/homeWeDo/cust.png" alt="" id="laptopimage" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobileContainer">
      <div>
        <p id="mobileHeading">Oracle Consulting</p>
        <img
          src="../../../../assets/homeWeDo/weDoOne.png"
          alt=""
          id="mobileImage"
        />
        <p id="mobileText">
          Our Oracle consulting services are aimed at helping businesses
          optimize their use of Oracle products and maximize their return on
          investment. Our services are delivered by highly skilled consultants
          who possess in-depth knowledge and expertise in Oracle EBS, Oracle
          Fusion, and custom solutions. Our consultants work closely with
          clients to understand their unique requirements, provide strategic
          advice, and implement Oracle solutions tailored to their specific
          needs.
        </p>
      </div>
      <div>
        <p id="mobileHeading">Oracle Implementation</p>
        <img
          src="../../../../assets/homeWeDo/weDoTwo.png"
          alt=""
          id="mobileImage"
        />
        <p id="mobileText">
          Our implementations often involve integrating the Oracle solution with
          other enterprise systems, such as ERP systems, CRM platforms, or data
          warehouses. Our consultants design and implement interfaces and
          integration mechanisms to enable seamless data flow between systems.
        </p>
      </div>
      <div>
        <p id="mobileHeading">Managed Services</p>
        <img
          src="../../../../assets/homeWeDo/weDoThree.png"
          alt=""
          id="mobileImage"
        />
        <p id="mobileText">
          Our Oracle Managed Services consultants are highly skilled
          professionals with in-depth knowledge of Oracle's vast product
          portfolio, including but not limited to Oracle Database, Oracle Cloud
          Applications, Oracle E-Business Suite, Oracle Fusion Middleware, and
          more. <br />
          They comprehensively understand how these solutions can be tailored to
          meet specific business needs and challenges.
        </p>
      </div>
      <div>
        <p id="mobileHeading">Realapp</p>
        <img
          src="../../../../assets/homeWeDo/weDoFour.png"
          alt=""
          id="mobileImage"
        />
        <p id="mobileText">
          Our applications are designed to meet specific business requirements
          and are developed from scratch, taking into account the unique needs
          and objectives of the organization.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./whatWeDo.js"></script>
<style src="./whatWeDo.css"></style>
