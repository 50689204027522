<template>
    <div>
        <first />
        <second />
        <third />
        <fourth />
    </div>
</template>

<script src="./managedServices.js"></script>
<style src="./managedServices.css"></style>