<template>
  <div>
    <carousel-3d>
      <slide :index="0">
        Slide 1 content
      </slide>
      <slide :index="1">
        Slide 2 content
      </slide>
    </carousel-3d>
  </div>
</template>