<template>
  <div class="manufacturingFourth">
    <p class="manufacturingFourthText">
      We offer a range of ERP (Enterprise Resource Planning) solutions that
      cater to various industries, including the real estate industry. These ERP
      solutions are designed to help businesses streamline their operations,
      manage finances, improve collaboration, and enhance overall efficiency.
      Here's an overview of how CCS's ERP solutions can be utilized in the
      real estate industry:
    </p>
    <div class="careersThird">
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle Cloud ERP</h1>
          <p>
            Oracle Cloud ERP is a cloud-based solution that covers a wide range
            of business functions including finance, procurement, project
            management, and more. For the real estate industry, Oracle Cloud ERP
            can be customized to handle financial management, lease management,
            property management, and other industry-specific processes. It
            provides real-time visibility into financial data, helps manage
            leases and contracts, and offers analytics to make informed
            decisions.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/generalLedger.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/accountsPayable.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle E-Business Suite (EBS) Real Estate Management</h1>
          <p>
            As mentioned earlier, Oracle EBS Real Estate Management is a
            dedicated module within the E-Business Suite that focuses on
            managing various aspects of real estate operations, including lease
            management, property management, maintenance, and financials.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
