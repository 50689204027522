<template>
  <div>
    <div>
      <p class="our">Our <span class="partners">Clients</span> </p>
    </div>
  <div class="slider">
    <PartnerLogo
      v-for="(imageName, index) in imageNames"
      :key="index"
      :imageName="imageName"
    />
  </div>
</div>
</template>

<script src="./partner"></script> 
<style src="./partner.css"></style>
