<template>
  <div class="careersThird">
    <div class="careersThirdFirstBox">
      <div>
        <img
          src="../../../../assets/careers/ourCulture.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
      <div>
        <h1>Our Culture</h1>
        <p>
          At the heart of CCS is a culture that celebrates diversity, encourages
          creativity, and fosters an environment of mutual respect. We believe
          that when people from different backgrounds and perspectives come
          together, amazing things happen. Our inclusive culture ensures that
          every voice is heard and contributes to the collective success of our
          team.
        </p>
      </div>
    </div>
    <div class="careersThirdSecondBox">
      <div>
        <h1>Innovative Spirit</h1>
        <p>
          We're pioneers, always pushing the boundaries of what's possible. Our
          commitment to innovation drives us to seek out new ideas, explore
          uncharted territories, and find solutions that make a real impact.
          Join us, and you'll be surrounded by individuals who thrive on turning
          challenges into opportunities.
        </p>
      </div>
      <div>
        <img
          src="../../../../assets/careers/innovativeSpirit.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
    </div>
    <div class="careersThirdFirstBox">
      <div>
        <img
          src="../../../../assets/careers/collaboration.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
      <div>
        <h1>Collaboration in Action</h1>
        <p>
          When you join CCS, you're not just joining a team you're becoming part
          of a collaborative force that's passionate about working together to
          achieve remarkable outcomes. Our open and transparent communication
          ensures that ideas flow freely, knowledge is shared generously, and
          our collective efforts are amplified.
        </p>
      </div>
    </div>
    <div class="careersThirdSecondBox">
      <div>
        <h1>Learning and Development</h1>
        <p>
          Your growth matters to us. We're dedicated to providing you with the
          tools and resources you need to excel in your career. Through
          mentorship programs, workshops, training sessions, and continuous
          learning opportunities, you'll have the support to reach new heights
          and achieve your professional aspirations.
        </p>
      </div>
      <div>
        <img
          src="../../../../assets/careers/learning.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
    </div>
    <div class="careersThirdFirstBox">
      <div>
        <img
          src="../../../../assets/careers/workLifeBalance.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
      <div>
        <h1>Work-Life Integration</h1>
        <p>
          We understand the importance of balance. With flexible work
          arrangements and a supportive environment, you'll have the freedom to
          manage your work commitments while taking care of your personal
          well-being. Our aim is to ensure that you thrive both inside and
          outside of the workplace.
        </p>
      </div>
    </div>
    <div class="careersThirdSecondBox">
      <div>
        <h1>Meaningful Impact</h1>
        <p>
          At CCS, your contributions make a difference. Whether you're
          collaborating on cutting-edge projects, engaging with clients to solve
          complex challenges, or driving initiatives that align with our
          mission, you'll see firsthand the impact of your work on industries,
          communities, and beyond.
        </p>
      </div>
      <div>
        <img
          src="../../../../assets/careers/meaningfulImpact.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
    </div>
    <div class="careersThirdFirstBox">
      <div>
        <img
          src="../../../../assets/careers/celebratingTogether.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
      <div>
        <h1>Celebrating Together</h1>
        <p>
          Work doesn't always have to be serious. We believe in celebrating our
          successes, milestones, and even the everyday wins. From team-building
          activities and company-wide events to casual get-togethers, you'll
          have plenty of opportunities to connect with your colleagues in a
          relaxed and enjoyable atmosphere.
        </p>
      </div>
    </div>
    <div class="careersThirdSecondBox">
      <div>
        <h1>Join Our Team</h1>
        <p>
          Are you ready to be part of something bigger than just a job? Explore
          our current openings and discover how you can bring your skills,
          passion, and unique perspective to CCS. Join us in shaping
          a future where your career flourishes and your experiences are truly
          rewarding.
        </p>
      </div>
      <div>
        <img
          src="../../../../assets/careers/join.jpg"
          class="careersBoxImageOne"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
