<template>
  <div class="manufacturingFourth">
    <p class="manufacturingFourthText">Why Choose Connaissant?</p>
    <div class="careersThird">
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Expertise and Experience</h1>
          <p>
            With a seasoned team of developers and a track record of successful
            projects, we bring a wealth of expertise to every web application
            development endeavor.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/customApplications/customApplications1.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/customApplications/customApplications2.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Agile Development Methodology</h1>
          <p>
            We follow an agile approach, allowing for flexibility, quick
            iterations, and continuous improvement throughout the development
            process.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>On-Time Delivery</h1>
          <p>
            We understand the importance of timelines. Our commitment to
            punctuality ensures your web application is delivered within
            agreed-upon deadlines.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/customApplications/customApplications3.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      Elevate your online presence and user experience with our web application
      development services. Partner with CCS to embark on a journey of digital
      innovation and success. Contact us today to discuss your project and bring
      your vision to life.
    </p>
  </div>
</template>

<script src="./fifth.js"></script>
<style src="./fifth.css"></style>
