import { createRouter, createWebHistory } from "vue-router";
import home from "./components/pages/home";
import aboutUs from "./components/pages/aboutUs";
import careers from "./components/pages/careers";
import contact from "./components/pages/contact";
import industries from "./components/pages/industries";
import partners from "./components/pages/partners";
import ourTeam from "./components/pages/ourTeam";
import leadership from "./components/pages/leadership";
import testimonials from "./components/pages/testimonials";
import oracleConsulting from "./components/pages/oracleConsulting";
import oracleImplementation from "./components/pages/oracleImplementation";
import managedServices from "./components/pages/managedServices";
import realapp from "./components/pages/realapp";
import gst from "./components/pages/gst";
import manufacturing from "./components/pages/manufacturing";
import financial from "./components/pages/financial";
import realEstate from "./components/pages/realEstate";
import telecom from "./components/pages/telecom";
import logistics from "./components/pages/logistics";
import adminDashboard from "./adminDashboard";
// import pageNotFound from "./components/pages/pageNotFound";
import banking from "./components/pages/banking";
import vendorPortal from "./components/pages/vendorPortal";
import customAplications from "./components/pages/customAplications";
import cms from "./components/pages/cms";



const routes = [
  { path: "/", component: home },
  { path: "/about-us", component: aboutUs },
  { path: "/careers", component: careers },
  { path: "/contact", component: contact },
  { path: "/industries", component: industries },
  { path: "/partners", component: partners },
  { path: "/ourTeam", component: ourTeam },
  { path: "/leadership", component: leadership },
  { path: "/testimonials", component: testimonials },
  { path: "/oracleConsulting", component: oracleConsulting },
  { path: "/oracleImplementation", component: oracleImplementation },
  { path: "/managedServices", component: managedServices },
  { path: "/realapp", component: realapp },
  { path: "/gst", component: gst },
  { path: "/manufacturing", component: manufacturing },
  { path: "/financial", component: financial },
  { path: "/realEstate", component: realEstate },
  { path: "/telecom", component: telecom },
  { path: "/logistics", component: logistics },
  { path: "/admin", component: adminDashboard },
  // { path: '*', component: pageNotFound },
  { path: "/banking", component: banking },
  { path: "/vendorPortal", component: vendorPortal },
  { path: "/customAplications", component: customAplications },
  { path: "/cms", component: cms },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
