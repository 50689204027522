<template>
  <div>
    <!-- <h1>Leadership</h1> -->
    <div class="ccs_partners">
      <div class="ccs_partner1">
        <div class="ccs_partner1_img">
          <div>
            <img src="../../../assets/leadership/Sanjay_Vig.svg" alt="" />
          </div>
        </div>
        <div class="ccs_partner1_det">
          <h2>Sanjay Vig</h2>
          <h4>Director</h4>
          <h5>
            Highly accomplished and visionary director with a proven track
            record of 27 years in Oracle ERP Implementation & ERP Consulting.
            Recognized for driving organizational success through strategic
            leadership, innovative thinking, and a commitment to excellence.
            Adept at steering teams toward achieving and surpassing goals while
            navigating complex challenges. Known for fostering a collaborative
            and results-driven work culture.
          </h5>
        </div>
      </div>
      <div class="leadershipSkills">
        <div>
          <h4>Visionary Leadership</h4>
          <p>
            At the core of Sanjay's leadership philosophy is a visionary
            approach that looks beyond the present and envisions the future.
            With a strategic mindset and an eye for innovation, he is dedicated
            to steering Connaissant Consultancy Services Pvt. Ltd (CCS) towards
            sustainable growth and enduring success.
          </p>
        </div>
        <div>
          <h4>Strategic Planning</h4>
          <p>
            Adept at navigating complex market landscapes, Sanjay is
            instrumental in crafting and executing the strategic initiatives
            that drive our company forward. By leveraging industry insights and
            fostering a culture of forward-thinking, he ensures that CCS remains
            at the forefront of industry trends.
          </p>
        </div>
        <div>
          <h4>Innovation & Adaptability</h4>
          <p>
            In an era of rapid change, Sanjay encourages a culture of innovation
            and adaptability. By embracing emerging technologies and encouraging
            creative thinking, he positions CCS as a dynamic and forward-looking
            industry leader.
          </p>
        </div>
        <div>
          <h4>Financial Acumen</h4>
          <p>
            Strong financial management skills, including budgeting,
            forecasting, and resource allocation.
          </p>
        </div>
        <div>
          <h4>Stakeholder Management</h4>
          <p>
            Recognizing the importance of strong relationships, Sanjay actively
            engages with stakeholders to foster trust and collaboration. By
            maintaining open lines of communication with shareholders,
            employees, customers, and partners.
          </p>
        </div>
        <div>
          <h4>Decision-Making</h4>
          <p>
            Sound decision-maker with a keen ability to analyze situations and
            make informed choices to drive success.
          </p>
        </div>
      </div>
      <div class="experience">
        <div>
          <h1>Experience</h1>
          <p>
            Worked with PricewaterhouseCoopers Limited in a Leadership role in
            Oracle Practice
          </p>
          <h1>Education</h1>
          <p>
            Qualified Chartered Accountant having more than 27 Years of
            Experience. Graduate from Kirori Mal College Delhi in 1992
          </p>
        </div>
        <div>
          <h4>Notable Projects</h4>
          <ul>
            <li>IFFCO Tokio General Insurance</li>
            <li>Delhivery</li>
            <li>OYO</li>
            <li>PolicyBazaar</li>
            <li>Maharashtra Seamless Limited</li>
            <li>IFFCO</li>
            <li>Vatika Limited</li>
          </ul>
          <h4>Personal Qualities</h4>
          <li>Visionary leader</li>
          <li>Excellent communicator</li>
          <li>Adaptive and resilient</li>
          <li>Problem solver</li>
          <li>Commitment to continuous learning</li>
        </div>
      </div>
    </div>
    <div class="ccs_partners">
      <div class="ccs_partner1">
        <div class="ccs_partner1_img">
          <div>
            <img src="../../../assets/leadership/Neelam_Upreti.svg" alt="" />
          </div>
        </div>
        <div class="ccs_partner1_det">
          <h2>Neelam Upreti</h2>
          <h4>Director</h4>
          <h5>
            Neelam Upreti is a first-generation entrepreneur having 18 years of
            industry experience. She is an MSC IT by profession. She has
            developed solutions for various industries like General Insurance,
            Investment Banking, Process Manufacturing, and Real Estate. Before
            starting this company, she worked for companies like
            PricewaterhouseCoopers, Open Software Technologies, Sapient
            Technologies, and Lehman Brothers India Pvt. Limited She has
            experience in the implementation of Oracle EBS, Custom Solution in
            Java/J2EE, and Reporting tool.
          </h5>
        </div>
      </div>
      <div class="leadershipSkills">
        <div>
          <h4>Leadership Embodied</h4>
          <p>
            Neelam Upreti epitomizes strong leadership qualities, steering the
            company with vision, purpose, and inspiration. Her decisive actions
            and strategic vision shape the company's direction.
          </p>
        </div>
        <div>
          <h4>Visionary Approach</h4>
          <p>
            Neelam Upreti possesses an innate ability to think beyond the
            present, crafting a clear and compelling vision for the company's
            future. Her forward-thinking mindset enables her to anticipate
            market shifts and position the company advantageously.
          </p>
        </div>
        <div>
          <h4>Master of Strategy</h4>
          <p>
            With Neelam Upreti at the helm, strategic planning becomes an art
            form. She meticulously sets goals, devises comprehensive plans, and
            adeptly navigates complexities to achieve sustainable growth and
            success.
          </p>
        </div>
        <div>
          <h4>Financial Wizardry</h4>
          <p>
            Neelam Upreti's profound understanding of financial intricacies
            ensures sound fiscal management. Armed with insights from financial
            data, she makes informed decisions, safeguarding the company's
            financial health and maximizing shareholder value.
          </p>
        </div>
        <div>
          <h4>Unyielding Integrity</h4>
          <p>
            Neelam Upreti is a beacon of integrity, upholding the highest
            ethical standards in every facet of company affairs. Her commitment
            to transparency, honesty, and accountability sets a sterling example
            for all.
          </p>
        </div>
        <div>
          <h4>Industry Luminary</h4>
          <p>
            Neelam Upreti's profound industry knowledge serves as a guiding
            light, illuminating pathways to success amidst evolving landscapes.
            Her keen insights into market trends, competitors, and regulations
            inform strategic decision-making.
          </p>
        </div>
      </div>
      <div class="experience">
        <div>
          <h1>Experience</h1>
          <p>
            Worked with PricewaterhouseCoopers Limited, Lehman Brothers India
            Pvt. Ltd, Sapient Technologies & Open Software Technologies
          </p>
          <h1>Education</h1>
          <p>
            Masters in Computer Science in Information Technology with 22+ Years
            of techno-functional experience
          </p>
        </div>
        <div>
          <h4>Notable Projects</h4>
          <ul>
            <li>Experion Developers Private Limited</li>
            <li>
              WorleyParsons Australia for PwC (Oracle PO/Inventory)
              -Implementation
            </li>
            <li>GST implementation Winsome Yarns</li>
            <li>Delhivery- Oracle EBS Implementation and support</li>
            <li>Aitel GST –IRN Project</li>
            <li>Indus Towers- GST 2A Implementation</li>
            <li>OYO- Implementation & Support</li>
            <li>Snapdeal</li>
            <li>PolicyBazaar</li>
          </ul>
          <h4>Personal Qualities</h4>
          <li>Innovation Catalyst</li>
          <li>Conflict Resolution Virtuoso</li>
          <li>Legal and Regulatory Savvy</li>
          <li>Unwavering Commitment</li>
          <li>Seasoned Experience</li>
        </div>
      </div>
    </div>
    <div class="ccs_partners">
      <div class="ccs_partner1">
        <div class="ccs_partner1_img">
          <div>
            <img
              src="../../../assets/leadership/Amritansh_Rastogi.svg"
              alt=""
            />
          </div>
        </div>
        <div class="ccs_partner1_det">
          <h2>Amritansh Rastogi</h2>
          <h4>Director</h4>
          <h5>
            IT professional with 12+ year of experience. Extensively worked on
            Procure-to-Pay and Order-to-Cash cycle. Experienced in requirement
            gathering, conceptualizing,designing and implementation of
            customized and Oracle R12 EBS package
            (AP /AR /GL /CM /FA /Purchasing /Inventory module) with an excellent track
            record.Strong analytic skills, effective decision making and team
            building skills to manage various stakeholders.
          </h5>
        </div>
      </div>
      <div class="leadershipSkills">
        <div>
          <h4>Strategic Genius</h4>
          <p>
            Amritansh Rastogi possesses a strategic mindset, adeptly mapping out
            pathways to success. His strategic acumen enables the company to
            seize opportunities, navigate challenges, and stay ahead of the
            curve.
          </p>
        </div>
        <div>
          <h4>Ethical Paragon</h4>
          <p>
            Amritansh Rastogi is a beacon of ethics and integrity, embodying
            honesty and transparency in all endeavors. His unwavering commitment
            to ethical conduct sets the standard for corporate governance.
          </p>
        </div>
        <div>
          <h4>Industry Expertise</h4>
          <p>
            Amritansh Rastogi's deep industry knowledge is a strategic asset,
            guiding the company through market intricacies. His insights into
            industry trends and dynamics inform strategic decision-making.
          </p>
        </div>
        <div>
          <h4>Adaptability Champion</h4>
          <p>
            Amritansh Rastogi embraces change as a catalyst for growth and
            innovation. His adaptability enables the company to navigate
            evolving landscapes and capitalize on emerging opportunities.
          </p>
        </div>
        <div>
          <h4>Seasoned Experience</h4>
          <p>
            With a wealth of leadership experience, Amritansh Rastogi brings
            invaluable insights to the boardroom. His seasoned perspective
            enriches strategic discussions and informs decision-making.
          </p>
        </div>
        <div>
          <h4>Innovation Catalyst</h4>
          <p>
            Amritansh Rastogi champions innovation, fostering a culture of
            creativity and experimentation. His support for innovative
            initiatives drives competitiveness and fuels future growth.
          </p>
        </div>
      </div>
      <div class="experience">
        <div>
          <h1>Experience</h1>
          <p>
            Worked with M3M India Private Limited, Infosys Limited & Tata
            Consultancy Services
          </p>
          <h1>Education</h1>
          <p>
            B.Tech (Hons.) from VIT, Vellore & MBA(Finance) from SCMHRD, Pune
            with 9 + years of experience
          </p>
        </div>
        <div>
          <h4>Notable Projects</h4>
          <ul>
            <li>IFFCO GST Reconciliation Project –Oracle EBS</li>
            <li>Suntrust Bank – Oracle Payables Implementation</li>
            <li>Westpac Bank – Oracle GL Implementation</li>
            <li>Integration of custom modules with SAP-FICO</li>
            <li>
              Airtel – Oracle EBS 12.1.3 – einvocing implementation & design for
              finance modules
            </li>
            <li>
              Delhivery –Oracle EBS 12.1.3 implementation and support
              Financials/PO/Inventory
            </li>
            <li>
              Ansal API Infrastructure Limited – Oracle EBS ( Oracle Financials,
              PO/Inventory)
            </li>
          </ul>
          <h4>Personal Qualities</h4>
          <li>Inspirational Leadership</li>
          <li>Effective Communication</li>
          <li>Risk Management Virtuoso</li>
          <li>Collaborative Advocate</li>
          <li>Commitment to Excellence</li>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="./leadership.css"></style>
<script src="./leadership.js"></script>
