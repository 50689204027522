<template>
  <div class="faq">
    <div class="faqs">
      <h1>Frequently Asked Questions</h1>

      <button class="accordion">
        What does your consultancy company specialize in?
      </button>
      <div class="panel">
        <p>
          Our consultancy firm specializes in providing IT consulting like
          Oracle ERP solutions, GST Solutions, and custom integration with
          bolt-on solutions, focusing on enhancing operational efficiency,
          driving growth, and optimizing business processes for various
          industries.
        </p>
      </div>

      <button class="accordion">How experienced are your consultants?</button>
      <div class="panel">
        <p>
          Our team of consultants comprises seasoned professionals with diverse
          backgrounds and a wealth of experience. On average, our consultants
          have more than 10 years of industry experience and a proven track
          record of delivering successful projects.
        </p>
      </div>

      <button class="accordion">What industries do you serve?</button>
      <div class="panel">
        <p>
          We have worked with clients from a wide range of industries, including
          BFSI, Manufacturing, Infrastructure & Real Estate, Logistics, and
          Telecom. Our versatile expertise allows us to cater to the unique
          needs of each industry.
        </p>
      </div>

      <button class="accordion">What types of services do you offer?</button>
      <div class="panel">
        <p>
          Our consultancy offers a comprehensive suite of services, including
          Oracle ERP Implementation, Oracle Support, GST Solutions, and Custom
          Application Development.
        </p>
      </div>
      <button class="accordion">
        How do you approach problem-solving for clients?
      </button>
      <div class="panel">
        <p>
          Our problem-solving approach is collaborative and data-driven. We
          first conduct in-depth analyses to understand the client's challenges
          and then develop customized solutions tailored to their specific needs
          and objectives.
        </p>
      </div>
      <button class="accordion">
        What is your typical project timeline and process?
      </button>
      <div class="panel">
        <p>
          Project timelines and processes vary depending on the scope and
          complexity of each engagement. Generally, we begin with a thorough
          discovery phase, followed by solution development, implementation, and
          ongoing support. Projects typically range from a few weeks to several
          months.
        </p>
      </div>
      <button class="accordion">
        Can you provide references or case studies from past clients?
      </button>
      <div class="panel">
        <p>
          Absolutely! We take pride in our successful partnerships and have
          numerous satisfied clients. We'd be happy to share relevant case
          studies and put you in touch with some of our previous clients to
          discuss their experiences.
        </p>
      </div>
      <button class="accordion">
        How do you ensure confidentiality and data security?
      </button>
      <div class="panel">
        <p>
          Client confidentiality and data security are paramount to us. We
          adhere to strict internal protocols, sign non-disclosure agreements,
          and implement industry-leading cybersecurity measures to safeguard
          your sensitive information.
        </p>
      </div>
      <button class="accordion">
        How do you handle potential conflicts of interest?
      </button>
      <div class="panel">
        <p>
          We are committed to maintaining the highest ethical standards and
          transparency in our dealings. If any potential conflicts of interest
          arise, we handle them with utmost integrity, ensuring that the
          client's best interests are always prioritized.
        </p>
      </div>
      <button class="accordion">
        What are your pricing and payment terms?
      </button>
      <div class="panel">
        <p>
          Our pricing is tailored to the specific requirements of each project.
          We offer competitive rates and flexible payment terms to accommodate
          our clients' preferences. A detailed breakdown of costs and payment
          schedules will be provided during the initial consultation phase.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./faqs.js"></script>
<style src="./faqs.css"></style>
