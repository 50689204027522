import { RouterLink } from "vue-router";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "Navbar",
  data() {
    return {
      scrollFlag: false,
      logo: require("@/assets/connaissantLogo.png"),
      oracle: require("@/assets/logos/oraclePartnerRed.png"),
      isDropdownOpen: false,
      isScrolled: false
    };
  },
  components: {
    RouterLink,
  },
  mounted() {
    const navbar = document.querySelector(".navbar");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 90) {
        navbar.classList.add("scrolled-bg");
        this.scrollFlag = true;
        this.isScrolled = true;
        this.logo = require("@/assets/connaissantLogoScrolled.png");
        this.oracle = require("@/assets/logos/oraclePartnerWhite.png");
      } else {
        navbar.classList.remove("scrolled-bg");
        this.scrollFlag = false;
        this.isScrolled = false;
        this.logo = require("@/assets/connaissantLogo.png");
        this.oracle = require("@/assets/logos/oraclePartnerRed.png");
      }
    });
  },
  methods: {
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
  },
};
