<template>
  <div>
    <div class="whyChoseUs">
      <h1 class="whyChoseHeading">Benefits</h1>
      <div v-if="isLaptop" class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-expertise-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-expertise"
            type="button"
            role="tab"
            aria-controls="v-pills-expertise"
            aria-selected="true"
          >
            Improved Business Processes
          </button>
          <button
            class="nav-link"
            id="v-pills-tailoredSolutions-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-tailoredSolutions"
            type="button"
            role="tab"
            aria-controls="v-pills-tailoredSolutions"
            aria-selected="false"
          >
            Efficient Implementation
          </button>
          <button
            class="nav-link"
            id="v-pills-seamlessImplementation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-seamlessImplementation"
            type="button"
            role="tab"
            aria-controls="v-pills-seamlessImplementation"
            aria-selected="false"
          >
            Scalable Platform
          </button>
        </div>
        <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-expertise"
            role="tabpanel"
            aria-labelledby="v-pills-expertise-tab"
          >
            <h1>Improved Business Processes</h1>
            The best practices of a combined Oracle-CSS solution can help you
            streamline and optimize your business processes. Based on our
            field-proven experience, we help you navigate critical
            “customization vs. business process change” decisions and ensure the
            best outcome for your business.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tailoredSolutions"
            role="tabpanel"
            aria-labelledby="v-pills-tailoredSolutions-tab"
          >
            <h1>Efficient Implementation</h1>
            CCS can help leverage your current staff during implementation,
            saving your business time and money. Our proven project management
            methodology helps ensure your project stays on time and on-budget.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-seamlessImplementation"
            role="tabpanel"
            aria-labelledby="v-pills-seamlessImplementation-tab"
          >
            <h1>Scalable Platform</h1>
            Both from a technical and functional perspective, you know your
            business will evolve. We can help you set up and support an Oracle
            platform that will grow easily with you. From training to managed
            services, CCS can also deliver the post-implementation support you
            need.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Implementation and Upgrades</h1>
          <p>
            The best practices of a combined Oracle-CSS solution can help you
            streamline and optimize your business processes. Based on our
            field-proven experience, we help you navigate critical
            “customization vs. business process change” decisions and ensure the
            best outcome for your business.
          </p>
        </div>
        <div>
          <h1>Customization and Integration</h1>
          <p>
            CCS can help leverage your current staff during implementation,
            saving your business time and money. Our proven project management
            methodology helps ensure your project stays on time and on-budget.
          </p>
        </div>
        <div>
          <h1>Business Process Optimization</h1>
          <p>
            Both from a technical and functional perspective, you know your
            business will evolve. We can help you set up and support an Oracle
            platform that will grow easily with you. From training to managed
            services, CCS can also deliver the post-implementation support you
            need.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
