import featuresBenefits from "./featuresBenefits"
export default {
    name:'OpenKMPartner',
    components:{featuresBenefits},
    data() {
        return {
            
        }
    },
    mounted() {
        
    },
    methods: {
        
    },
 }