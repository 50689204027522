<template>
    <div class="gstSeventh">
        <div class="gstSeventhOne">
            <div>
                <p>Compliance Dashboard</p>
            </div>
            <div>
                <img src="../../../../assets/GST/complianceDashboard.png" alt="">
            </div>
        </div>
        <div class="gstSeventhTwo">
            <div>
                <img src="../../../../assets/GST/2Breconciliation.png" alt="">
            </div>
            <div>
                <p>2B Reconciliation</p>
            </div>
        </div>
        <div class="gstSeventhOne">
            <div>
                <p>2B Data Updation</p>
            </div>
            <div>
                <img src="../../../../assets/GST/2BdataUpdation.png" alt="">
            </div>
        </div>
        <div class="gstSeventhTwo">
            <div>
                <img src="../../../../assets/GST/2Adownload.png" alt="">
            </div>
            <div>
                <p>2A Download</p>
            </div>
        </div>
        <div class="gstSeventhOne">
            <div>
                <p>Near Match Form</p>
            </div>
            <div>
                <img src="../../../../assets/GST/nearMatchForm.png" alt="">
            </div>
        </div>
        <div class="gstSeventhTwo">
            <div>
                <img src="../../../../assets/GST/partyVarification.png" alt="">
            </div>
            <div>
                <p>Party Verification</p>
            </div>
        </div>
        <div class="gstSeventhOne">
            <div>
                <p>Vendor Hold/Unhold Mapping</p>
            </div>
            <div>
                <img src="../../../../assets/GST/vendrHoldMapping.png" alt="">
            </div>
        </div>
    </div>
</template>

<script src="./seventh.js"></script>
<style src="./seventh.css"></style>