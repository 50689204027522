<template>
  <div>
    <div class="whyChoseUs">
      <h1 class="whyChoseHeading">Why Choose Oracle Managed Services</h1>
      <div v-if="isLaptop" class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-expertise-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-expertise"
            type="button"
            role="tab"
            aria-controls="v-pills-expertise"
            aria-selected="true"
          >
            Expert Oracle Specialists
          </button>
          <button
            class="nav-link"
            id="v-pills-tailoredSolutions-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-tailoredSolutions"
            type="button"
            role="tab"
            aria-controls="v-pills-tailoredSolutions"
            aria-selected="false"
          >
            Continuous Monitoring and Proactive Support
          </button>
          <button
            class="nav-link"
            id="v-pills-seamlessImplementation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-seamlessImplementation"
            type="button"
            role="tab"
            aria-controls="v-pills-seamlessImplementation"
            aria-selected="false"
          >
            Tailored Solutions
          </button>
          <button
            class="nav-link"
            id="v-pills-ongoingSupport-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-ongoingSupport"
            type="button"
            role="tab"
            aria-controls="v-pills-ongoingSupport"
            aria-selected="false"
          >
            Cost-Effectiveness
          </button>
          <button
            class="nav-link"
            id="v-pills-enhanced-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-enhanced"
            type="button"
            role="tab"
            aria-controls="v-pills-enhanced"
            aria-selected="false"
          >
            Enhanced Security and Compliance
          </button>
          <button
            class="nav-link"
            id="v-pills-access-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-access"
            type="button"
            role="tab"
            aria-controls="v-pills-access"
            aria-selected="false"
          >
            Access to Latest Oracle Updates
          </button>
        </div>
        <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-expertise"
            role="tabpanel"
            aria-labelledby="v-pills-expertise-tab"
          >
            <h1>Expert Oracle Specialists</h1>
            Our team consists of highly skilled and Oracle-certified
            professionals with in-depth knowledge and years of experience in
            managing Oracle technologies. With their expertise, we offer
            efficient troubleshooting, proactive maintenance, and reliable
            support.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tailoredSolutions"
            role="tabpanel"
            aria-labelledby="v-pills-tailoredSolutions-tab"
          >
            <h1>Continuous Monitoring and Proactive Support</h1>
            We understand the criticality of your Oracle environment. Our
            managed services include 24/7 monitoring, performance tuning, and
            proactive issue detection to ensure your systems operate at peak
            efficiency, minimizing downtime and disruptions.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-seamlessImplementation"
            role="tabpanel"
            aria-labelledby="v-pills-seamlessImplementation-tab"
          >
            <h1>Tailored Solutions</h1>
            Every business is unique, and so are its Oracle requirements. Our
            Oracle Managed Services are customized to align with your specific
            needs, ensuring that you receive the support and level of service
            that perfectly fits your organization.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-ongoingSupport"
            role="tabpanel"
            aria-labelledby="v-pills-ongoingSupport-tab"
          >
            <h1>Cost-Effectiveness</h1>
            Outsourcing Oracle management to our dedicated team can save you
            from the overhead costs of hiring and training in-house staff. Our
            services are designed to provide maximum value while optimizing your
            Oracle investments.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-enhanced"
            role="tabpanel"
            aria-labelledby="v-pills-enhanced-tab"
          >
            <h1>Enhanced Security and Compliance</h1>
            Protecting your data and ensuring compliance with industry
            regulations is our top priority. Our Oracle Managed Services adhere
            to best security practices, safeguarding your systems from potential
            threats and ensuring data integrity.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-access"
            role="tabpanel"
            aria-labelledby="v-pills-access-tab"
          >
            <h1>Access to Latest Oracle Updates</h1>
            As an Oracle Managed Services provider, we stay up-to-date with the
            latest Oracle updates, patches, and enhancements. You can rest
            assured that your Oracle environment will always be equipped with
            the latest features and improvements.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Expert Oracle Specialists</h1>
          <p>
            Our team consists of highly skilled and Oracle-certified
            professionals with in-depth knowledge and years of experience in
            managing Oracle technologies. With their expertise, we offer
            efficient troubleshooting, proactive maintenance, and reliable
            support.
          </p>
        </div>
        <div>
          <h1>Continuous Monitoring and Proactive Support</h1>
          <p>
            We understand the criticality of your Oracle environment. Our
            managed services include 24/7 monitoring, performance tuning, and
            proactive issue detection to ensure your systems operate at peak
            efficiency, minimizing downtime and disruptions.
          </p>
        </div>
        <div>
          <h1>Tailored Solutions</h1>
          <p>
            Every business is unique, and so are its Oracle requirements. Our
            Oracle Managed Services are customized to align with your specific
            needs, ensuring that you receive the support and level of service
            that perfectly fits your organization.
          </p>
        </div>
        <div>
          <h1>Cost-Effectiveness</h1>
          <p>
            Outsourcing Oracle management to our dedicated team can save you
            from the overhead costs of hiring and training in-house staff. Our
            services are designed to provide maximum value while optimizing your
            Oracle investments.
          </p>
        </div>
        <div>
          <h1>Enhanced Security and Compliance</h1>
          <p>
            Protecting your data and ensuring compliance with industry
            regulations is our top priority. Our Oracle Managed Services adhere
            to best security practices, safeguarding your systems from potential
            threats and ensuring data integrity.
          </p>
        </div>
        <div>
          <h1>Access to Latest Oracle Updates</h1>
          <p>
            As an Oracle Managed Services provider, we stay up-to-date with the
            latest Oracle updates, patches, and enhancements. You can rest
            assured that your Oracle environment will always be equipped with
            the latest features and improvements.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
