<template>
  <div  class="six-observe-me">
    <div class="whyChoseUs">
      <h1 class="whyChoseHeading">Our Fusion Services</h1>
      <div v-if="isLaptop" class="d-flex align-items-start sixth">
        <div
          class="nav flex-column nav-pills me-3 six-observe-me"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs six-observe-me2"
            id="v-pills-cloud-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-cloud"
            type="button"
            role="tab"
            aria-controls="v-pills-cloud"
            aria-selected="true"
          >
            Cloud Migration and Implementation
          </button>
          <button
            class="nav-link six-observe-me2"
            id="v-pills-fusion-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-fusion"
            type="button"
            role="tab"
            aria-controls="v-pills-fusion"
            aria-selected="false"
          >
            Fusion Extensions and Custom Development
          </button>
          <button
            class="nav-link six-observe-me2"
            id="v-pills-reporting-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-reporting"
            type="button"
            role="tab"
            aria-controls="v-pills-reporting"
            aria-selected="false"
          >
            Reporting and Analytics
          </button>
          <button
            class="nav-link six-observe-me2"
            id="v-pills-integration-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-integration"
            type="button"
            role="tab"
            aria-controls="v-pills-integration"
            aria-selected="false"
          >
            Integration with Existing Systems
          </button>
        </div>
        <div class="tab-content whyChoseUsTex" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-cloud"
            role="tabpanel"
            aria-labelledby="v-pills-cloud-tab"
          >
            <h1>Cloud Migration and Implementation</h1>
            Embrace the power of the cloud with our seamless Fusion migration
            and implementation services. We ensure a secure and efficient
            transfer of your data and business processes to the cloud
            environment.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-fusion"
            role="tabpanel"
            aria-labelledby="v-pills-fusion-tab"
          >
            <h1>Fusion Extensions and Custom Development</h1>
            Our developers can extend the functionality of Oracle Fusion to meet
            your unique business requirements through custom applications and
            integrations.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-reporting"
            role="tabpanel"
            aria-labelledby="v-pills-reporting-tab"
          >
            <h1>Reporting and Analytics</h1>
            Leverage the advanced reporting and analytics capabilities of Oracle
            Fusion to gain real-time insights and make data-driven decisions.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-integration"
            role="tabpanel"
            aria-labelledby="v-pills-integration-tab"
          >
            <h1>Integration with Existing Systems</h1>
            We understand the importance of a connected business ecosystem. Our
            experts integrate Oracle Fusion with your current applications for a
            cohesive and efficient workflow.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Cloud Migration and Implementation</h1>
          <p>
            Embrace the power of the cloud with our seamless Fusion migration
            and implementation services. We ensure a secure and efficient
            transfer of your data and business processes to the cloud
            environment.
          </p>
        </div>
        <div>
          <h1>Fusion Extensions and Custom Development</h1>
          <p>
            Our developers can extend the functionality of Oracle Fusion to meet
            your unique business requirements through custom applications and
            integrations.
          </p>
        </div>
        <div>
          <h1>Reporting and Analytics</h1>
          <p>
            Leverage the advanced reporting and analytics capabilities of Oracle
            Fusion to gain real-time insights and make data-driven decisions.
          </p>
        </div>
        <div>
          <h1>Integration with Existing Systems</h1>
          <p>
            We understand the importance of a connected business ecosystem. Our
            experts integrate Oracle Fusion with your current applications for a
            cohesive and efficient workflow.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./sixth.js"></script>
<style src="./sixth.css"></style>
