<template>
  <div class="aboutUsFourthPage">
    <div class="aboutFirstHeading thr-observe-me">
      <h1>Oracle E-Business Suite (EBS):</h1>
      <p>
        Oracle E-Business Suite has been a cornerstone of enterprise software
        solutions for decades, offering a comprehensive suite of integrated
        applications to streamline various business functions. Our team of
        seasoned Oracle EBS consultants brings extensive experience in
        implementing, optimizing, and supporting EBS across diverse industries.
      </p>
    </div>
    <div class="thr-observe-me2">
      <img
        src="../../../../assets/oracleConsulting/oracleConsultingTwo.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
