<template>
  <div class="manufacturingFourth">
    <p class="manufacturingFourthText">Benefits:</p>
    <div class="careersThird">
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Efficient Workflow</h1>
          <p>
            Streamline the entire collection management workflow, from
            acquisition and cataloging to exhibition and conservation, reducing
            manual processes and improving overall efficiency.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/customApplications/customApplications1.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/customApplications/customApplications2.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Enhanced Accessibility</h1>
          <p>
            Facilitate easy access to collection data for researchers,
            educators, and the public. Provide online catalogs or portals,
            allowing users to explore your collection remotely.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Improved Conservation Practices</h1>
          <p>
            Maintain detailed records of conservation efforts, enabling a
            proactive approach to preservation. Implement best practices for
            handling, storing, and restoring items in your collection.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/customApplications/customApplications3.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/customApplications/customApplications2.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Comprehensive Reporting</h1>
          <p>
            Generate detailed reports on collection status, usage, and trends.
            Use data analytics to make informed decisions about acquisitions,
            exhibitions, and conservation strategies.   
          </p>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      Elevate your online presence and user experience with our web application
      development services. Partner with CCS to embark on a journey of digital
      innovation and success. Contact us today to discuss your project and bring
      your vision to life.
    </p>
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
