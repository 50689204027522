<template>
  <div class="manufacturingThird mt-4">
    <div class="logisticsThird">
      <div class="managedServicesFourthFirst">
        <div>
          <div>
            <h1>{{ heading }}</h1>
            {{ textContent }}
          </div>
        </div>
        <div>
          <div
          :class="heading === 'Seamless Integration of Processes' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('sip')"
          >
            Seamless Integration of Processes
          </div>
          <div
          :class="heading === 'Supply Chain Visibility and Tracking' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('scvt')"
          >
            Supply Chain Visibility and Tracking
          </div>
          <div
          :class="heading === 'Warehouse Management and Optimization' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('wmo')"
          >
            Warehouse Management and Optimization
          </div>
          <div
          :class="heading === 'Demand Forecasting and Planning' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('dfp')"
          >
            Demand Forecasting and Planning
          </div>
          <div
          :class="heading === 'Route Optimization and Transportation Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('rotm')"
          >
            Route Optimization and Transportation Management
          </div>
          <div
          :class="heading === 'Compliance and Regulatory Adherence' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('cra')"
          >
            Compliance and Regulatory Adherence
          </div>
          <div
          :class="heading === 'Data-driven Decision Making' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('ddm')"
          >
            Data-driven Decision Making
          </div>
          <div
          :class="heading === 'Cloud-based Scalability and Innovation' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('csi')"
          >
            Cloud-based Scalability and Innovation
          </div>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      As an Oracle partner specializing in ERP solutions for the logistics
      industry, we are instrumental in shaping the future of logistics
      operations. Our collaboration with Oracle empowers logistics companies to
      streamline processes, enhance supply chain visibility, optimize
      transportation, and make data-driven decisions. By tailoring Our Oracle
      ERP solutions to the unique needs of the logistics sector, we contribute
      to increased efficiency, customer satisfaction, and competitiveness in the
      dynamic logistics landscape.
    </p>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
