<template>
  <div class="oracleConsultingSecond sec-observe-me">
    <h1>Empowering Innovation, Enabling Growth, and Unleashing Potentia</h1>
    <p>
      At our consulting firm, we specialize in providing comprehensive solutions
      for both Oracle EBS and Oracle Fusion, enabling your organization to
      harness the power of cutting-edge technologies and seamlessly integrate
      your business processes.
    </p>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
