<template>
  <div>
    <div class="alert alert-warning" role="alert" v-if="showAlert">
      We will revert back to you soon!
    </div>
    <div class="alert alert-warning" role="alert" v-else-if="mandateAlert">
      Kindly fill the mandatory fields
    </div>
    <div class="lets_connect">
      <div>
        <h1 id="lets_connect_heading">Need a Consultation?</h1>
        <p>Connect with us to get the best Consultancy Services</p>
      </div>
      <div>
        <label for="name" class="letsConnectLabel mandatory">Full Name</label>
        <br />
        <input
          type="text"
          v-model="ConsultationName"
          placeholder="Full Name"
          class="letsConnectInput"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Full Name'"
        />
        <br />
        <br />
        <label for="email" class="letsConnectLabel mandatory">Email</label>
        <br />
        <input
          v-model="ConsultationEmail"
          type="email"
          placeholder="Email"
          class="letsConnectInput"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Email'"
        />
        <br />
        <br />
        <label for="organization" class="letsConnectLabel">Organization</label>
        <br />
        <input
          v-model="ConsultationOrg"
          type="text"
          placeholder="Organization"
          class="letsConnectInput"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Organization'"
        />
        <br /><br />
      </div>
      <div>
        <label for="jobTitle" class="letsConnectLabel">Job Title</label>
        <br />
        <input
          v-model="ConsultationJobTitle"
          type="text"
          placeholder="Job Title"
          class="letsConnectInput"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Job Title'"
        />
        <br />
        <br />
        <label for="contactNumber" class="letsConnectLabel mandatory"
          >Contact Number</label
        >
        <br />
        <input
          v-model="ConsultationNumber"
          type="number"
          placeholder="Contact Number"
          class="letsConnectInput"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Contact Number'"
        />
        <br />
        <br />
        <label for="companyName" class="letsConnectLabel">Employee size</label>
        <br />
        <select
          class="form-select mt-3"
          aria-label="Default select example"
          v-model="value"
        >
          <option selected>0-50</option>
          <option value="0-50">0-50</option>
          <option value="50-100">50-100</option>
          <option value="100-200">100-200</option>
          <option value="200-500">200-500</option>
          <option value="500-1000">500-1000</option>
          <option value="1000-5000">1000-5000</option>
          <option value="More Than 5000">More Than 5000</option>
        </select>
      </div>
    </div>
    <div class="submitButton">
      <button class="letsConnectButton" @click="submitDetails()">Submit</button>
    </div>
  </div>
</template>

<style src="./letsConnect.css"></style>
<script src="./letsConnect.js"></script>
