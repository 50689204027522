<template>
  <div class="oracleOurPartner">
    <!-- <h1 class="whyChoseHeading">Why Chose Us?</h1> -->
    <div v-if="isLaptop" class="d-flex align-items-start">
      <div
        class="nav flex-column nav-pills me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link active tabs"
          id="v-pills-scalability-performance-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-scalability-performance"
          type="button"
          role="tab"
          aria-controls="v-pills-scalability-performance"
          aria-selected="true"
        >
          Scalability and Performance
        </button>
        <button
          class="nav-link"
          id="v-pills-high-speed-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-high-speed"
          type="button"
          role="tab"
          aria-controls="v-pills-high-speed"
          aria-selected="false"
        >
          High-Speed Document Retrieval
        </button>
        <button
          class="nav-link"
          id="v-pills-robust-compression-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-robust-compression"
          type="button"
          role="tab"
          aria-controls="v-pills-robust-compression"
          aria-selected="false"
        >
          Robust Data Compression
        </button>
        <button
          class="nav-link"
          id="v-pills-multi-user-collaboration-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-multi-user-collaboration"
          type="button"
          role="tab"
          aria-controls="v-pills-multi-user-collaboration"
          aria-selected="false"
        >
          Multi-User Collaboration
        </button>
        <button
          class="nav-link"
          id="v-pills-advancedSecurity-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-advancedSecurity"
          type="button"
          role="tab"
          aria-controls="v-pills-advancedSecurity"
          aria-selected="false"
        >
          Advanced Security Features
        </button>
      </div>
      <div class="tab-content oracleOurPartText" id="v-pills-tabContent">
        <div
          class="tab-pane show active fade"
          id="v-pills-scalability-performance"
          role="tabpanel"
          aria-labelledby="v-pills-scalability-performance-tab"
        >
          <h1>Scalability and Performance</h1>
          Our DMS is built to handle heavy data loads with ease. As your
          organization grows and data accumulates, our solution scales
          effortlessly to meet your needs, ensuring consistent performance and
          seamless document management.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-high-speed"
          role="tabpanel"
          aria-labelledby="v-pills-high-speed-tab"
        >
          <h1>High-Speed Document Retrieval</h1>
          No more waiting for documents to load or sifting through endless
          files. Our DMS's lightning-fast retrieval capabilities allow you to
          access your documents in the blink of an eye, regardless of data
          volume.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-robust-compression"
          role="tabpanel"
          aria-labelledby="v-pills-robust-compression-tab"
        >
          <h1>Robust Data Compression</h1>
          Don't let heavy data eat up your storage space. Our DMS employs
          advanced data compression techniques to optimize storage utilization,
          reducing the need for costly storage expansions.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-multi-user-collaboration"
          role="tabpanel"
          aria-labelledby="v-pills-multi-user-collaboration-tab"
        >
          <h1>Multi-User Collaboration</h1>
          Collaborate with your team without worrying about data congestion. Our
          DMS facilitates real-time multi-user collaboration, allowing multiple
          users to work on documents simultaneously without compromising on
          performance.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-advancedSecurity"
          role="tabpanel"
          aria-labelledby="v-pills-advancedSecurity-tab"
        >
          <h1>Advanced Security Features</h1>
          Protect your valuable data with our robust security measures. Our DMS
          ensures data encryption, access controls, and audit trails,
          safeguarding your documents against unauthorized access or data
          breaches.
        </div>
      </div>
    </div>
    <div v-else class="oracleOurPartMobile">
      <div>
        <h1>Scalability and Performance</h1>
        <p>
          Our DMS is built to handle heavy data loads with ease. As your
          organization grows and data accumulates, our solution scales
          effortlessly to meet your needs, ensuring consistent performance and
          seamless document management.
        </p>
      </div>
      <div>
        <h1>High-Speed Document Retrieval</h1>
        <p>
          No more waiting for documents to load or sifting through endless
          files. Our DMS's lightning-fast retrieval capabilities allow you to
          access your documents in the blink of an eye, regardless of data
          volume.
        </p>
      </div>
      <div>
        <h1>Robust Data Compression</h1>
        <p>
          Don't let heavy data eat up your storage space. Our DMS employs
          advanced data compression techniques to optimize storage utilization,
          reducing the need for costly storage expansions.
        </p>
      </div>
      <div>
        <h1>Multi-User Collaboration</h1>
        <p>
          Collaborate with your team without worrying about data congestion. Our
          DMS facilitates real-time multi-user collaboration, allowing multiple
          users to work on documents simultaneously without compromising on
          performance.
        </p>
      </div>
      <div>
        <h1>Advanced Security Features</h1>
        <p>
          Protect your valuable data with our robust security measures. Our DMS
          ensures data encryption, access controls, and audit trails,
          safeguarding your documents against unauthorized access or data
          breaches.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./featuresBenefits"></script>
<style src="./featuresBenefits.css"></style>
