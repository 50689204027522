<template>
  <div>
    <div class="footer">
      <div class="footerConent">
        <div>
          <h1>About Us</h1>
          <p>
            CCS has enormous experience in working with ERP applications
            including Oracle EBS ,client-server, distributed web technologies
          </p>
        </div>
        <div>
          <h1>Links</h1>
          <a href="/about-us" class="no-underline"> <p>About Connaissant</p></a>
          <a href="/partners" class="no-underline"> <p>Our Partners</p></a>
          <a href="/ourTeam" class="no-underline"> <p>Our Team</p></a>
          <a href="/leadership" class="no-underline"> <p>Leadership</p></a>
          <a href="/testimonials" class="no-underline"> <p>Testimonials</p></a>
        </div>
        <div>
          <h1>Contact Us</h1>
          <p>
            <span><i class="bi bi-envelope"></i>durgvijay.sahani@connaissant.com</span>
            
          </p>
          <p>
            <span><i class="bi bi-house-door-fill"></i></span>Connaissant
            Consultancy Services Pvt. Ltd. <br />
            Plot No.56, Second Floor, Sector 44, Gurugram
          </p>
          <p>
            <span><i class="bi bi-telephone-fill"></i></span>+91-8178992075
          </p>
        </div>
        <div class="footer_icons">
          <a href="#" class="no-underline-icons">
            <i class="bi bi-facebook"></i
          ></a>
          <a href="#" class="no-underline-icons">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 20 20">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
</svg></a>
          <a
            href="https://www.linkedin.com/company/connaissant-consultancy-services-private-ltd/"
            class="no-underline-icons"
          >
            <i class="bi bi-linkedin"></i
          ></a>
          <a href="#" class="no-underline-icons">
            <i class="bi bi-google"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="copyright">
    <div class="footer_foot">©Copyright {{ date }} Connaissant Consultancy Services</div>
    <div class="footer_foot1">Image Credit 'Freepik'</div>
  </div>
  </div>
</template>

<style src="./footer.css"></style>
<script src="./footer.js"></script>
