<template>
  <div class="aboutUsThirdPage">
    <div class="fif-observe-me">
      <img
        src="../../../../assets/oracleConsulting/oracleConsultingThree.jpg"
        alt=""
      />
    </div>
    <div class="aboutFirstHeading fif-observe-me2">
      <h1>Oracle Fusion</h1>
      <p>
        Oracle Fusion is the next-generation, cloud-based suite of applications
        that combines the best of enterprise resource planning (ERP), human
        capital management (HCM), customer relationship management (CRM), and
        more. Our Oracle Fusion experts are well-versed in the latest cloud
        technologies and can guide you through the seamless adoption of Fusion.
      </p>
    </div>
  </div>
</template>

<script src="./fifth.js"></script>
<style src="./fifth.css"></style>
