<template>
  <div class="manufacturingFourth">
    <p class="manufacturingFourthText">
      Oracle Financials is a comprehensive suite of applications that provide
      organizations with tools to manage their financial processes, optimize
      resource allocation, and ensure compliance. The suite includes a range of
      core modules that cover various aspects of financial management. Here are
      some of the core modules within Oracle Financials we serve
    </p>
    <div class="careersThird">
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle General Ledger (GL)</h1>
          <p>
            The General Ledger module is the foundation of Oracle Financials. It
            provides a central repository for financial data, allowing
            organizations to track and manage their financial transactions,
            accounts, and balances. It supports financial reporting, budgeting,
            and consolidation.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/generalLedger.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/accountsPayable.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle Accounts Payable (AP)</h1>
          <p>
            The Accounts Payable module helps organizations manage their payable
            processes. It includes features for processing vendor invoices,
            managing payment terms, and tracking outstanding liabilities.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle Accounts Receivable (AR):</h1>
          <p>
            The Accounts Receivable module facilitates the management of
            customer invoices and receipts. It enables organizations to track
            customer transactions, manage credit limits, and improve cash flow.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/accountsRecievable.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/cashManagement.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle Cash Management</h1>
          <p>
            The Cash Management module focuses on optimizing cash flow and
            liquidity. It helps organizations monitor their cash positions,
            manage bank accounts, reconcile statements, and forecast cash needs.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle Fixed Assets</h1>
          <p>
            The Fixed Assets module assists organizations in managing their
            tangible assets. It includes features for asset tracking,
            depreciation calculation, and compliance with accounting standards.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/fixedAssets.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/taxManagement.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle Tax Management</h1>
          <p>
            The Tax Management module helps organizations manage their tax
            obligations. It assists in calculating, tracking, and reporting on
            various taxes, ensuring compliance with tax regulations.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle Advanced Collections</h1>
          <p>
            The Advanced Collections module supports the management of
            collections processes. It helps organizations streamline their
            collections efforts, track overdue accounts, and improve cash flow.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/advancedCollections.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/ebusinessTax.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle eBusiness Tax</h1>
          <p>
            The eBusiness Tax module enables organizations to manage complex tax
            requirements. It supports various tax configurations, calculations,
            and reporting based on local tax regulations.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Oracle Payroll</h1>
          <p>
            While not exclusively part of Oracle Financials, the Oracle Payroll
            module is often integrated to manage employee compensation and
            benefits. It supports payroll processing, tax calculations, and
            compliance with labor regulations.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/financial/fixedAssets.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/financial/ebusinessTax.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Oracle Financial Analytics</h1>
          <p>
            Oracle Financial Analytics provides advanced reporting and analytics
            capabilities. It allows organizations to gain insights into
            financial performance, monitor key performance indicators (KPIs),
            and make informed decisions.
          </p>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      These core modules within Oracle Financials are designed to work
      seamlessly together, allowing organizations to manage their financial
      processes holistically. The suite supports automation, streamlining of
      workflows, reduction of manual errors, and improved visibility into
      financial data. Organizations can select the specific modules that align
      with their needs and gradually expand their usage as their requirements
      evolve. Keep in mind that the availability of specific modules and
      features may vary based on the Oracle Financials version and
      configuration. For detailed consultation, please reach out to us, we would
      be happy to discuss.
    </p>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
