<template>
  <div>
    <div class="whyChoseUs">
      <h1 class="whyChoseHeading">Choosing the Right GST Solution</h1>
      <div v-if="isLaptop" class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-expertise-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-expertise"
            type="button"
            role="tab"
            aria-controls="v-pills-expertise"
            aria-selected="true"
          >
            Scalability
          </button>
          <button
            class="nav-link"
            id="v-pills-tailoredSolutions-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-tailoredSolutions"
            type="button"
            role="tab"
            aria-controls="v-pills-tailoredSolutions"
            aria-selected="false"
          >
            User-Friendly Interface
          </button>
          <button
            class="nav-link"
            id="v-pills-seamlessImplementation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-seamlessImplementation"
            type="button"
            role="tab"
            aria-controls="v-pills-seamlessImplementation"
            aria-selected="false"
          >
            Comprehensive Support
          </button>
          <button
            class="nav-link"
            id="v-pills-customization-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-customization"
            type="button"
            role="tab"
            aria-controls="v-pills-customization"
            aria-selected="false"
          >
            Customization
          </button>
          <button
            class="nav-link"
            id="v-pills-integrations-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-integrations"
            type="button"
            role="tab"
            aria-controls="v-pills-integrations"
            aria-selected="false"
          >
            Integration
          </button>
        </div>
        <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-expertise"
            role="tabpanel"
            aria-labelledby="v-pills-expertise-tab"
          >
            <h1>Scalability</h1>
            Ensure the chosen solution can handle the growth of your business
            without compromising performance
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tailoredSolutions"
            role="tabpanel"
            aria-labelledby="v-pills-tailoredSolutions-tab"
          >
            <h1>User-Friendly Interface</h1>
            A user-friendly interface simplifies adoption and reduces the
            learning curve for your team.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-seamlessImplementation"
            role="tabpanel"
            aria-labelledby="v-pills-seamlessImplementation-tab"
          >
            <h1>Comprehensive Support</h1>
            Look for solutions with responsive customer support to address any
            issues or queries promptly
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-customization"
            role="tabpanel"
            aria-labelledby="v-pills-customization-tab"
          >
            <h1>Customization</h1>
            The solution should align with your business's unique requirements
            and adapt to changes in regulations.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-integrations"
            role="tabpanel"
            aria-labelledby="v-pills-integrations-tab"
          >
            <h1>Integration</h1>
            If you have existing systems in place, choose a solution that can
            seamlessly integrate with them.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Scalability</h1>
          <p>
            Ensure the chosen solution can handle the growth of your business
            without compromising performance
          </p>
        </div>
        <div>
          <h1>User-Friendly Interface</h1>
          <p>
            A user-friendly interface simplifies adoption and reduces the
            learning curve for your team.
          </p>
        </div>
        <div>
          <h1>Comprehensive Support</h1>
          <p>
            Look for solutions with responsive customer support to address any
            issues or queries promptly
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./sixth.js"></script>
<style src="./sixth.css"></style>
