<template>
  <div class="solutions">
    <h1>Solutions we deliver</h1>
    <p>
      Tailored Solutions for Your Unique Challenges: Empowering Your Success
    </p>
    <div class="solutions_container">
        <div>Oracle Fusion</div>
        <div>Oracle EBS(R12)</div>
        <div>GST Solutions</div>
        <div>E-invoice</div>
        <div>E-way bill</div>
        <div>Real estate leasing</div>
        <div>Vendor Portal</div>
        <div>Document Management -OpenKM</div>
        <div>Facility management</div>
        <div>Workflow</div>
        <div>Assets Tracking</div>
        <div>Collection Management System</div>
    </div>
  </div>
</template>

<style src="./solutions.css"></style>
