<template>
  <div>
    <div class="testimonials">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            data-bs-interval="2000"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            data-bs-interval="2000"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            data-bs-interval="2000"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="carousel_container">
              <div>
                <h1>Delhivery</h1>
                <p>
                  Conniassant has played a pivotal role in the turn-around for
                  Oracle EBS implementation for Delhivery Limited and has
                  delivered out-of-the-box solutions to handle critical business
                  processes – such as Cash-on-Delivery, GST reconciliation, bank
                  payments, provision automation, etc.
                  <br />
                  Thank you, Connaissant, for empowering our success and being
                  an invaluable part of our journey towards excellence!
                </p>
              </div>
              <img
                src="../../../../assets/home/testimonials/delhivery.png"
                class="d-block"
                alt="..."
              />
            </div>
          </div>
          <div class="carousel-item">
            <div class="carousel_container">
              <div>
                <h1>OYO</h1>
                <p>
                  Oyo had challenges around customizations such as vendor
                  onboarding, license optimization, SLA-GL reconciliation, and
                  bank integration. Connissant consultants have delivered in the
                  following areas: <br />
                  1. Identifying and fixing the custom code bugs <br />
                  2. Identifying areas for business process improvement and
                  implementing the same <br />
                  3. Developing new functionalities to support business
                  functions and license optimization. <br />Thank you
                  Connaissant for your support.
                </p>
              </div>
              <img
                src="../../../../assets/home/testimonials/oyo.svg"
                class="d-block"
                alt="..."
              />
            </div>
          </div>
          <div class="carousel-item">
            <div class="carousel_container">
              <div>
                <h1>Maharashtra Seamless Limited- MSL</h1>
                <p>
                  Our earlier implementation partner left in the middle of the
                  project, and no handover was provided but Connaissant
                  implemented it properly and streamlined our business process
                  properly.
                </p>
              </div>
              <img
                src="../../../../assets/home/testimonials/msl.svg"
                class="d-block"
                alt="..."
              />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- <div class="testimonials1"></div> -->
  </div>
</template>

<script></script>

<style src="./testimonials.css"></style>
