<template>
  <div class="gstThird">
    <div class="gstThirdContainer">
      <div>
        <h1>The Need for GST Solutions</h1>
        <p>
          The introduction of GST brought about significant changes in tax
          calculations, reporting, and compliance. This complexity necessitates
          the integration of technology to ensure accurate and timely GST
          filing. Our GST solution software platform helps businesses manage
          various aspects of GST compliance, from accurate tax calculation to
          seamless filing of returns.
        </p>
      </div>
      <div>
        <img src="../../../../assets/GST/gstThird.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
