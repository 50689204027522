<template>
  <div>
    <div class="about" :class="{ active: animate }">
      <div class="aboutHeader">
        <img
          src="../../../assets/aboutUs/aboutUsHeader.jpg"
          alt="aboutHeader"
          id="aboutHeaderImage"
        />
      </div>
      <div class="top-left">
        <h1 class="drop-from-top">
          About Us
        </h1>
        <p class="drop-from-bottom">
          Empowering Growth through Strategic <br />
          Solutions
        </p>
      </div>
    </div>
    <div class="aboutUsSecondPage">
      <div class="aboutFirstHeading abt-observe-me">
        <h1>About Us</h1>
        <p>
          Started in 2011 with a mission to cater to the needs of growth of
          enterprise customers. We started with Connaissant Consultancy Services a firm
          with a team of business consultants having rich experience and
          expertise in diverse domains like Banking, Financial and insurance,
          Discrete Manufacturing, Process Manufacturing, Real estate industry.
          <br />
          In 2013, we registered our company with the name Connaissant
          Consultancy Services (CCS). <br />
          CCS has more than 100+ resources and rich experience in diverse
          technologies & and consulting. <br />
          The core team of Connaissant comprises professionals like Chartered
          Accountants having more than 25 years of experience, MCA/Engineers
          having more than 18 years of experience & and MBAs having more than 15
          years of experience. <br />
          We can help in streamlining business processes and optimizing your
          enterprise's performance. With years of experience and a team of
          seasoned experts, we are committed to delivering innovative and
          tailored solutions that empower your organization to achieve its full
          potential.
        </p>
      </div>
      <div class="abt-observe-me2">
        <img src="../../../assets/aboutUs/aboutUs.jpg" alt="" />
      </div>
    </div>
    
      <div class="aboutUsThirdPage">
        <div class="abt-observe-me">
          <h1 class="ourMission">Our Mission</h1>
          <img src="../../../assets/aboutUs/ourMissionOne.jpg" alt="" />
        </div>
        <div class="aboutFirstHeading abt-observe-me2">
          <p>
            At Connaissant, our mission is to assist businesses in harnessing
            the true power of Oracle's Enterprise Resource Planning (ERP) suite.
            We aim to simplify complex business operations, enhance
            decision-making processes, and drive sustainable growth for our
            clients. By leveraging our deep knowledge of Oracle ERP, we enable
            organizations to stay ahead in today's competitive landscape.
          </p>
        </div>
      </div>
      <div class="aboutUsFourthPage">
        <div class="aboutFirstHeading abt-observe-me2">
          <p>
            We have the vision to become 'the most preferred solution partner'.
            Our end-to-end solution expertise ensures a seamless extension of
            your processes cutting across barriers of 'space and time'. So if
            you are caught in the maze of too many options or too few options,
            explore the spectrum of services that can be stitched to your fit.
            Your partner for delivering world-class solutions to make you
            realize the India advantage; with a wealth of domain expertise and
            cross-functional teams, we aspire to be your partner in your path to
            profitability. While synergizing our business advisory, outsourcing,
            Oracle applications, and Connaissant Consultancy Servicess applications, ERP
            domain expertise, we are often viewed by customers as a boutique for
            mission-critical services.
          </p>
        </div>
        <div class="abt-observe-me">
          <img src="../../../assets/aboutUs/ourMissionTwo.jpg" alt="" />
        </div>
      </div>
      <div class="aboutUsThirdPage">
        <div class="abt-observe-me2">
          <img src="../../../assets/aboutUs/ourMissionThree.jpg" alt="" />
        </div>
        <div class="aboutFirstHeading abt-observe-me">
          <p>
            The mission of Connaissant is to build and maintain healthy IT
            Platforms that exceed our partners' expectations. Connaissant is a
            technology architecture implementation and management firm that
            understands the importance of business dealings driven by integrity
            and respect. We bring to the partnership a portfolio of creative
            designs, highly skilled consultants, best-of-breed partnerships, and
            proven approaches to building business-critical infrastructures that
            meet or exceed our clients ’ expectations.
          </p>
        </div>
      </div>
    <whyChoseUs />
  </div>
</template>

<script src="./aboutUs.js"></script>
<style src="./aboutUs.css"></style>
