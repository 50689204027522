import first from "./first";
import second from "./second";
import third from "./third";


export default {
    name: 'financial',
    components: {
        first,
        second,
        third
    },
    data() {
    return {

    }
    }
}