<template>
  <div>
    <first />
    <second />
    <third />
    <fourth />
    <fifth />
    <sixth />
  </div>
</template>

<script src="./oracleConsulting.js"></script>
<style src="./oracleConsulting.css"></style>
