<template>
  <div class="vendorPortalSecond">
    <div>
      <h3>Centralized Vendor Information</h3>
      <p>
        Maintain a comprehensive and up-to-date database of all your vendors in
        one centralized location. Easily access details such as contact
        information, contracts, and performance metrics at a glance.
      </p>
    </div>
    <div>
      <h3>Real-Time Communication</h3>
      <p>
        Foster seamless communication with vendors through a dedicated messaging
        system. Stay connected in real-time, share updates, and discuss
        important matters within the secure confines of the portal.
      </p>
    </div>
    <div>
      <h3>Order and Inventory Management</h3>
      <p>
        Simplify procurement processes with integrated order and inventory
        management features. Track orders, manage stock levels, and receive
        timely updates on deliveries, ensuring a smooth supply chain.
      </p>
    </div>
    <div>
      <h3>Performance Analytics</h3>
      <p>
        Gain valuable insights into vendor performance through intuitive
        analytics dashboards. Track key metrics such as delivery times, product
        quality, and adherence to contractual terms, enabling data-driven
        decision-making.
      </p>
    </div>
    <div>
      <h3>Document Repository</h3>
      <p>
        Store and manage important documents, contracts, and compliance
        certificates securely. Facilitate easy document sharing with vendors
        while maintaining version control and ensuring regulatory compliance.
      </p>
    </div>
    <div>
      <h3>Automated Workflows</h3>
      <p>
        Streamline and automate routine processes, from purchase approvals to
        invoice processing. Improve efficiency, reduce manual errors, and
        enhance overall workflow management.
      </p>
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
