<template>
  <div class="vendorPortalFourth">
    <div>
      <h3>Customizable Solutions:</h3>
      <p>
        Tailor the vendor portal to suit the unique needs and workflows of your
        business, ensuring a seamless integration into your existing systems.
      </p>
    </div>
    <div>
      <h3>User-Friendly Interface</h3>
      <p>
        Our intuitive interface makes it easy for both your team and vendors to
        navigate the portal, minimizing training requirements and maximizing
        user adoption.
      </p>
    </div>
    <div>
      <h3>Scalable Architecture</h3>
      <p>
        Grow your vendor ecosystem without worrying about scalability. Vendor
        Portal is built to handle increasing data volumes and user loads as your
        business expands.
      </p>
    </div>
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
