<template>
    <div class="modal modal-xl fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Apply here</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="applyHereModal">
            <div>
                <h1>Personal Details</h1>
                <label for="form">First Name</label>
                <br>
                <input type="text" placeholder="First Name">
                <br>
                <label for="form">Last Name</label>
                <br>
                <input type="text" placeholder="Last Name">
                <br>
                <label for="form">Email</label>
                <br>
                <input type="email" placeholder="Email">
                <br>
                <label for="form">Contact Number</label>
                <br>
                <input type="phone" placeholder="Contact Number">
                <br>
                <label for="form">City</label>
                <br>
                <input type="email" placeholder="City">
                <br>
                <label for="form">State</label>
                <br>
                <input type="number" placeholder="State">
                <br>
            </div>
            <div>
                <h1>Qualifications</h1>
                <label for="form">First Name</label>
                <br>
                <input type="text" placeholder="First Name">
                <br>
                <label for="form">Last Name</label>
                <br>
                <input type="text" placeholder="Last Name">
                <br>
                <label for="form">Email</label>
                <br>
                <input type="email" placeholder="Email">
                <br>
                <label for="form">Contact Number</label>
                <br>
                <input type="number" placeholder="Contact Number">
                <br>
                <label for="form">Email</label>
                <br>
                <input type="email" placeholder="Email">
                <br>
                <label for="form">Contact Number</label>
                <br>
                <input type="number" placeholder="Contact Number">
                <br>
            </div>
            <div>
                <h1>Experience</h1>
                <label for="form">First Name</label>
                <br>
                <input type="text" placeholder="First Name">
                <br>
                <label for="form">Last Name</label>
                <br>
                <input type="text" placeholder="Last Name">
                <br>
                <label for="form">Email</label>
                <br>
                <input type="email" placeholder="Email">
                <br>
                <label for="form">Contact Number</label>
                <br>
                <input type="number" placeholder="Contact Number">
                <br>
                <label for="form">Email</label>
                <br>
                <input type="email" placeholder="Email">
                <br>
                <label for="form">Contact Number</label>
                <br>
                <input type="number" placeholder="Contact Number">
                <br>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./applyPage.js"></script>
<style src="./applyPage.css"></style>