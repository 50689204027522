<template>
  <div>
    <div class="careersFourth">
      <h1 class="careersHeading">We are looking for...</h1>
      <!-- <div class="applyBox">
        <h1>Frontend Developer</h1>
        <p>Job Location: <span>Gurgaon</span></p>
        <p>Notice Period: <span>Immediate Joining</span></p>
        <p>Experience: <span>3-5 Years</span></p>
        <p>
          Skills required: HTML, CSS, JavaScript, Vue.js/React.js
          <span>HTML</span>
        </p>
        <button type="button" class="" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply</button>
      </div>
      <div class="applyBox">
        <h1>Backend Developer</h1>
        <p>Job Location: <span>Gurgaon</span></p>
        <p>Notice Period: <span>1 months</span></p>
        <p>Experience: <span>5-7 Years</span></p>
        <p>Skills required: Java, MySQL, Springboot <span>HTML</span></p>
        <button type="button" class="" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply</button>
      </div>
      <div class="applyBox">
        <h1>Marketting Expert</h1>
        <p>Job Location: <span>Gurgaon</span></p>
        <p>Notice Period: <span>2 months</span></p>
        <p>Experience: <span>5-7 Years</span></p>
        <p>Skills required: Expert knowledge of Sales and Marketting</p>
      <button type="button" class="" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Apply
</button>
      </div> -->
      <h1>Currently we are not hiring</h1>
    </div>
    <applyPage />
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
