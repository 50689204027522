export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Oracle Database Management",
      selectedTab: "Oracle Database Management",
      textContent:
        "Ensure the reliability and performance of your Oracle databases with our proactive database management services. We handle installations, configurations, backups, recovery, and ongoing maintenance",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "cwa") {
        this.heading = "Custom Web Applications";
        this.selectedTab = "Custom Web Applications";
        this.textContent =
          "Tailored to your specific requirements, our custom web applications are designed to address your unique business challenges, providing a competitive edge in the digital landscape.";
      } else if (text === "ecs") {
        this.heading = "E-Commerce Solutions";
        this.selectedTab = "E-Commerce Solutions";
        this.textContent =
          "Elevate your online retail experience with e-commerce solutions that seamlessly integrate with your business processes, providing a secure and user-friendly platform for your customers..";
      } else if (text === "cms") {
        this.heading = "Content Management Systems (CMS) ";
        this.selectedTab = "Content Management Systems (CMS) ";
        this.textContent =
          "Take control of your website content with our custom CMS solutions, allowing you to manage, update, and scale your website effortlessly.";
      } else if (text === "adi") {
        this.heading = "API Development and Integration";
        this.selectedTab = "API Development and Integration";
        this.textContent =
          "Enhance functionality by seamlessly integrating your web application with third-party services, APIs, or other systems, ensuring a cohesive digital ecosystem.";
      } else if (text === "rwd") {
        this.heading = "Responsive Web Design";
        this.selectedTab = "Responsive Web Design";
        this.textContent =
          "Our web applications are designed to adapt to various devices and screen sizes, providing a consistent and optimal user experience across desktops, tablets, and smartphones.";
      }
    },
  },
};
