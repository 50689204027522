<template>
  <div>
    <div
      class="letsTalkButton"
      @click="openCloseLetsConnectModal()"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
    >
      <div id="telephoneIcon">
        <i class="bi bi-telephone-fill"></i>
      </div>
    </div>
    <navbar />
    <router-view></router-view>
    <Footer />
    <letsConnectModal />
  </div>
</template>

<script>
import navbar from "./components/navbar";
import Footer from "./components/Footer";
import letsConnectModal from "./components/letsConnectModal";
export default {
  name: "App",
  components: {
    navbar,
    Footer,
    letsConnectModal
  },
  methods: {
    openCloseLetsConnectModal() {
            this.showHideLetsConnectModal =!this.showHideLetsConnectModal;
        }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  color: #2c3e50;
  margin-top: 60px;
}
.letsTalkButton {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 500px;
  right: 0;
  background-color: #f38d26;
  z-index: 200;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: #003145 4px 4px 4px;
  text-align: center;
}
.letsTalkButton:hover {
    background-color: #003145;
    cursor: pointer;
}
#telephoneIcon {
    margin-top: 3px;
    font-size: 30px;
    color: white;
}
#letsConnect {
    color: white;
    font-size: 14px;
    font-family: "Raleway", sans-serif !important;
}
</style>
