<template>
  <div class="manufacturingSecond">
    <div>
      <p>
        We offer ERP solutions designed to optimize manufacturing processes and
        supply chain management. These solutions help manufacturers streamline
        production, manage inventory, monitor demand, and ensure efficient
        distribution. CCS Oracle solutions include features for demand
        forecasting, production planning, quality control, and real-time
        visibility into the supply chain. We offer both process
        manufacturing and discrete manufacturing: we offer two major enterprise
        software solutions for manufacturing industries: Oracle E-Business Suite
        (EBS) and Oracle Fusion Applications. Both of these suites include
        modules tailored for process manufacturing. Here's an overview of Oracle
        EBS Process Manufacturing and Oracle Fusion Process Manufacturing
      </p>
    </div>
    <div>
        <img src="../../../../assets/manufacturing/manufacturingSecond.jpg" alt="">
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
