<template>
  <div class="oracleImplementationSixth">
    <h1>Our GST Implementation:</h1>
    <div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthOne.jpg" alt="" />
          </div>
          <div>
            <p>Time Savings</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Automation of various GST-related tasks reduces manual effort,
            enabling businesses to focus on core operations.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthTwo.jpg" alt="" />
          </div>
          <div>
            <p>Reduced Errors</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            The automated nature of GST solutions minimizes human errors in tax
            calculations and return filings.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthThree.jpg" alt="" />
          </div>
          <div>
            <p>Compliance</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Stay up-to-date with GST regulations and filing requirements,
            reducing the risk of non-compliance penalties.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthFour.jpg" alt="" />
          </div>
          <div>
            <p>Efficient Workflow</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Streamlined processes improve the overall workflow, allowing
            businesses to manage GST-related tasks more effectively.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthFive.jpg" alt="" />
          </div>
          <div>
            <p>Data Security</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Many GST solutions offer secure storage and transmission of
            sensitive financial data, ensuring data privacy and confidentiality.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/GST/gstFifthSix.jpg" alt="" />
          </div>
          <div>
            <p>Analytics and Insights</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Generate reports and analytics on GST transactions, helping
            businesses make informed decisions based on financial data.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./fifth.js"></script>
<style src="./fifth.css"></style>
