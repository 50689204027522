<template>
    <div>
        <div class="about" :class="{ active: animate }">
      <div class="aboutHeader">
        <img
          src="../../../../assets/financial/financial.jpg"
          alt="aboutHeader"
          id="aboutHeaderImage"
        />
      </div>
      <div class="top-left">
        <h1 class="drop-from-top">
           CCS in Financial Services & Banking
        </h1>
        <p class="drop-from-bottom">
            Empowering Your Financial Future, One Step at a Time
        </p>
      </div>
    </div>
    </div>
</template>

<script src="./first.js"></script>
<style src="./first.css"></style>