export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Oracle Database Management",
      selectedTab:'Oracle Database Management',
      textContent:
        "Ensure the reliability and performance of your Oracle databases with our proactive database management services. We handle installations, configurations, backups, recovery, and ongoing maintenance",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "odm") {
        this.heading = "Oracle Database Management";
        this.selectedTab = 'Oracle Database Management';
        this.textContent =
          "Ensure the reliability and performance of your Oracle databases with our proactive database management services. We handle installations, configurations, backups, recovery, and ongoing maintenance.";
      } else if (text === "oas") {
        this.heading = "Oracle Application Support";
        this.selectedTab = "Oracle Application Support";
        this.textContent =
          "Get comprehensive support for your Oracle applications, including E-Business Suite. Our experts assist with upgrades, patching, troubleshooting, and performance optimization.";
      } else if (text === "ocm") {
        this.heading = "Oracle Cloud Management";
        this.selectedTab = "Oracle Cloud Management";
        this.textContent =
          "Leverage the full potential of Oracle Cloud Infrastructure (OCI) and Oracle Cloud Applications with our cloud management services. We provide cloud migration, optimization, and ongoing monitoring to maximize your cloud investment.";
      } else if (text === "pto") {
        this.heading = "Performance Tuning and Optimization";
        this.selectedTab = "Performance Tuning and Optimization";
        this.textContent =
          "Enhance the speed and efficiency of your Oracle systems with our performance tuning and optimization services. Our specialists fine-tune configurations and queries to deliver superior performance.";
      } else if (text === "sac") {
        this.heading = "Security and Compliance";
        this.selectedTab = "Security and Compliance";
        this.textContent =
          "Protect your Oracle environment from potential threats and ensure compliance with industry regulations. We implement security measures, access controls, and regular audits to safeguard your data.";
      }
    },
  },
};
