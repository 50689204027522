import first from "./first";
import second from "./second";
import third from "./third";

export default {
    name: 'contact',
    components: {
        first,
        second,
        third
    },
    data() {

    }
}