<template>
  <div class="oracleOurPartner">
    <!-- <h1 class="whyChoseHeading">Why Chose Us?</h1> -->
    <div v-if="isLaptop" class="d-flex align-items-start">
      <div
        class="nav flex-column nav-pills me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link active tabs"
          id="v-pills-expertise-experience-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-expertise-experience"
          type="button"
          role="tab"
          aria-controls="v-pills-expertise-experience"
          aria-selected="true"
        >
          Expertise and Experience
        </button>
        <button
          class="nav-link"
          id="v-pills-tailored-solutions-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-tailored-solutions"
          type="button"
          role="tab"
          aria-controls="v-pills-tailored-solutions"
          aria-selected="false"
        >
          Tailored Solutions
        </button>
        <button
          class="nav-link"
          id="v-pills-innovation-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-innovation"
          type="button"
          role="tab"
          aria-controls="v-pills-innovation"
          aria-selected="false"
        >
          Innovation at Its Best
        </button>
        <button
          class="nav-link"
          id="v-pills-OracleProducts-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-OracleProducts"
          type="button"
          role="tab"
          aria-controls="v-pills-OracleProducts"
          aria-selected="false"
        >
          Full Suite of Oracle Products
        </button>
        <!-- <button
            class="nav-link"
            id="v-pills-innovationDriven-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-innovationDriven"
            type="button"
            role="tab"
            aria-controls="v-pills-innovationDriven"
            aria-selected="false"
          >
            Innovation-driven
          </button> -->
      </div>
      <div class="tab-content oracleOurPartText" id="v-pills-tabContent">
        <div
          class="tab-pane show active fade"
          id="v-pills-expertise-experience"
          role="tabpanel"
          aria-labelledby="v-pills-expertise-experience-tab"
        >
          <h1>Expertise and Experience</h1>
          Our team of Oracle-certified professionals boasts years of experience
          and deep knowledge of Oracle's technologies. From Oracle Cloud
          Infrastructure (OCI) to Oracle Database, E-Business Suite, Fusion
          Applications, and more, we have the expertise to provide seamless
          implementation, integration, and ongoing support.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-tailored-solutions"
          role="tabpanel"
          aria-labelledby="v-pills-tailored-solutions-tab"
        >
          <h1>Tailored Solutions</h1>
          We understand that every business is unique. That's why we offer
          tailored solutions that align with your specific requirements,
          ensuring you get the most out of Oracle's offerings. Whether you need
          assistance with cloud migration, database optimization, application
          development, or analytics, we've got you covered.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-innovation"
          role="tabpanel"
          aria-labelledby="v-pills-innovation-tab"
        >
          <h1>Innovation at Its Best</h1>
          Oracle is at the forefront of technological advancements, and so are
          we. By partnering with us, you gain access to the latest tools and
          innovations that can propel your business ahead of the competition. We
          help you stay ahead in a dynamic market landscape by constantly
          exploring and implementing new technologies.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-OracleProducts"
          role="tabpanel"
          aria-labelledby="v-pills-OracleProducts-tab"
        >
          <h1>Full Suite of Oracle Products</h1>
          As an Oracle Partner, we have access to the entire range of Oracle
          products and services. This allows us to offer you a holistic approach
          to your technology needs, integrating different components seamlessly
          and providing you with comprehensive solutions.
        </div>
        <!-- <div
            class="tab-pane fade"
            id="v-pills-innovationDriven"
            role="tabpanel"
            aria-labelledby="v-pills-innovationDriven-tab"
          >
            <h1>Innovation-driven</h1>
            The world of ERP is constantly evolving, and we stay at the forefront
            of the latest trends and technologies. We bring the latest innovations
            to your organization, helping you adapt to the changing business
            landscape and gain a competitive edge.
          </div> -->
      </div>
    </div>
    <div v-else class="oracleOurPartMobile">
      <div>
        <h1>Expertise and Experience</h1>
        <p>
          Our team of Oracle-certified professionals boasts years of experience
          and deep knowledge of Oracle's technologies. From Oracle Cloud
          Infrastructure (OCI) to Oracle Database, E-Business Suite, Fusion
          Applications, and more, we have the expertise to provide seamless
          implementation, integration, and ongoing support.
        </p>
      </div>
      <div>
        <h1>Tailored Solutions</h1>
        <p>
          We understand that every business is unique. That's why we offer
          tailored solutions that align with your specific requirements,
          ensuring you get the most out of Oracle's offerings. Whether you need
          assistance with cloud migration, database optimization, application
          development, or analytics, we've got you covered.
        </p>
      </div>
      <div>
        <h1>Innovation at Its Best</h1>
        <p>
          Oracle is at the forefront of technological advancements, and so are
          we. By partnering with us, you gain access to the latest tools and
          innovations that can propel your business ahead of the competition. We
          help you stay ahead in a dynamic market landscape by constantly
          exploring and implementing new technologies.
        </p>
      </div>
      <div>
        <h1>Full Suite of Oracle Products</h1>
        <p>
          As an Oracle Partner, we have access to the entire range of Oracle
          products and services. This allows us to offer you a holistic approach
          to your technology needs, integrating different components seamlessly
          and providing you with comprehensive solutions.
        </p>
      </div>
      <!-- <div>
          <h1>Innovation-driven</h1>
          <p>
            The world of ERP is constantly evolving, and we stay at the forefront
            of the latest trends and technologies. We bring the latest innovations
            to your organization, helping you adapt to the changing business
            landscape and gain a competitive edge.
          </p>
        </div> -->
    </div>
  </div>
</template>

<script src="./oracleOurPartner"></script>
<style src="./oracleOurPartner.css"></style>
