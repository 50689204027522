export default {
    data() {
      return {
        screenWidth: window.innerWidth,
        observer:null
      };
    },
    computed: {
      isLaptop() {
        return this.screenWidth > 868;
      }
    },
    created() {
      window.addEventListener('resize', this.updateScreenWidth);
    },
    methods: {
      updateScreenWidth() {
        this.screenWidth = window.innerWidth;
      }
    },
    mounted() {
      this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("in-viewport");
                // this.observer.unobserve(entry.target)
              } else {
                entry.target.classList.remove("in-viewport");
              }
            });
          }
          );
          const hidele = document.querySelectorAll(".choseUs-observe-me");
          hidele.forEach((el) => this.observer.observe(el));
  
          const hidele1 = document.querySelectorAll(".choseUs-observe-me2");
          hidele1.forEach((el) => this.observer.observe(el));
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateScreenWidth);
    }
  };