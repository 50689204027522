export default {
    name: 'faqs',
    components: {},
    mounted() {
        if (document.querySelector(".accordion") !== null) {
            const acc = document.getElementsByClassName("accordion");

            this.openFirstAccTab(acc);
            this.attachClickListeners(acc);
        }
    },
    methods: {
        toggleAcc(e) {
            e.classList.toggle("active");
            var panel = e.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        },
        closeAccTabs(acc) {
            for (let i = 0; i < acc.length; i++) {
                if (acc[i].classList.contains("active")) {
                    acc[i].classList.remove("active");
                    acc[i].nextElementSibling.removeAttribute("style");
                }
            }
        },
        openFirstAccTab(acc) {
            if (!acc[0].classList.contains("active")) {
                acc[0].classList.add("active");
                acc[0].nextElementSibling.style.maxHeight =
                    acc[0].nextElementSibling.scrollHeight + "px";
            }
        },
        attachClickListeners(acc) {
            for (let i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", () => {
                    if (!acc[i].classList.contains("active")) {
                        this.closeAccTabs(acc);
                        this.toggleAcc(acc[i]);
                    } else {
                        this.closeAccTabs(acc);
                    }
                });
            }
        }
    }
};
