<template>
  <div class="oracleImplementationSixth">
    <h1>Key Features:</h1>
    <div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/blue.jpg" alt="" />
          </div>
          <div>
            <p>Centralized Cataloging</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Create a centralized repository for all your collections, from
            artworks and artifacts to books and documents. Easily catalog items,
            capturing essential details such as descriptions, provenance, and
            current location.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/red.jpg" alt="" />
          </div>
          <div>
            <p>Digitization and Multimedia Integration</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Seamlessly integrate multimedia elements, including images, videos,
            and audio recordings, to enhance the documentation of each item in
            your collection. Digitize archives and artifacts to preserve and
            share cultural heritage.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/teal.jpg" alt="" />
          </div>
          <div>
            <p>Advanced Search and Retrieval:</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Enable quick and precise retrieval of items through an advanced
            search functionality. Utilize filters, tags, and customizable search
            criteria to find specific items or explore the entire collection
            effortlessly.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/grey.jpg" alt="" />
          </div>
          <div>
            <p>Loan and Exhibition Management</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Track loans, manage exhibition schedules, and monitor the movement
            of items within and outside your institution. Ensure compliance with
            lending agreements and exhibition deadlines.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/lightBlue.jpg" alt="" />
          </div>
          <div>
            <p>Condition Monitoring and Conservation</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Implement a system for monitoring the condition of items over time.
            Schedule conservation tasks, track restoration efforts, and maintain
            detailed records of any changes in an item's state
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img src="../../../../assets/planeBackgrounds/black.jpg" alt="" />
          </div>
          <div>
            <p>Access Control and Security</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Implement robust access control mechanisms to protect sensitive
            information. Define user roles and permissions, ensuring that only
            authorized personnel have access to certain features or collections.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
