import oraclePartner from "./oraclePartner"
import openKmPartner from "./openKmPartner"

export default {
    name:'Partners',
    components:{
        oraclePartner,
        openKmPartner
    },
    data() {
        return {
            
        }
    },
    mounted() {
        
    },
    methods: {
        
    },
 }