<template>
  <div class="oracleImplementationSixth">
    <h1>Our Oracle Fusion Implementation Services:</h1>
    <div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationTwo.jpg"
              alt=""
            />
          </div>
          <div>
            <p>In-depth Consultation</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            We begin by understanding your organization's unique needs,
            challenges, and long-term objectives. Our team of experienced
            consultants collaborates with your key stakeholders to design a
            tailored implementation strategy that aligns perfectly with your
            business goals.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationThree.jpg"
              alt=""
            />
          </div>
          <div>
            <p>Cloud Migration</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Transitioning to the cloud is a pivotal step in embracing the power
            of Oracle Fusion. Our experts ensure a smooth and secure migration
            of your existing data and applications to the cloud environment,
            minimizing disruptions to your business operations.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationFour.jpg"
              alt=""
            />
          </div>
          <div>
            <p>Comprehensive Implementation</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Our seasoned Oracle Fusion implementation specialists bring deep
            expertise in deploying all modules of Fusion, from Finance and HCM
            to Supply Chain and beyond. With a meticulous approach, we configure
            the system to suit your organization's processes and workflows.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationFive.jpg"
              alt=""
            />
          </div>
          <div>
            <p>Customization and Integration</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            We understand that each organization has unique requirements. Our
            developers can extend the functionality of Oracle Fusion through
            custom applications and seamless integration with your existing
            systems, ensuring a cohesive and efficient ecosystem.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationSix.jpg"
              alt=""
            />
          </div>
          <div>
            <p>Training and Adoption</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            Successful Fusion implementation requires your team to be proficient
            in utilizing the platform's full potential. We provide comprehensive
            training sessions tailored to your users' roles, ensuring they are
            equipped to maximize productivity and efficiency.
          </p>
        </div>
      </div>
      <div class="oracleImplementationSixthCard">
        <div>
          <div>
            <img
              src="../../../../assets/oracleImplementation/oracleImplementationSeven.jpg"
              alt=""
            />
          </div>
          <div>
            <p>Data Security and Compliance</p>
          </div>
        </div>
        <div>
          <p class="hidden-text">
            We prioritize data security and compliance, implementing robust
            security measures to safeguard your critical business information
            and ensure adherence to industry regulations.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./sixth.js"></script>
<style src="./sixth.css"></style>
