<template>
  <div class="whyChoseUs choseUs-observe-me">
    <h1 class="whyChoseHeading">Why Chose Us?</h1>
    <div v-if="isLaptop" class="d-flex align-items-start">
      <div
        class="nav flex-column nav-pills me-3 choseUs-observe-me"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link active tabs choseUs-observe-me2"
          id="v-pills-expertise-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-expertise"
          type="button"
          role="tab"
          aria-controls="v-pills-expertise"
          aria-selected="true"
        >
          Expertise
        </button>
        <button
          class="nav-link choseUs-observe-me2"
          id="v-pills-tailoredSolutions-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-tailoredSolutions"
          type="button"
          role="tab"
          aria-controls="v-pills-tailoredSolutions"
          aria-selected="false"
        >
          Tailored Solutions
        </button>
        <button
          class="nav-link choseUs-observe-me2"
          id="v-pills-seamlessImplementation-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-seamlessImplementation"
          type="button"
          role="tab"
          aria-controls="v-pills-seamlessImplementation"
          aria-selected="false"
        >
          Seamless Implementation
        </button>
        <button
          class="nav-link choseUs-observe-me2"
          id="v-pills-ongoingSupport-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-ongoingSupport"
          type="button"
          role="tab"
          aria-controls="v-pills-ongoingSupport"
          aria-selected="false"
        >
          Ongoing Support
        </button>
        <button
          class="nav-link choseUs-observe-me2"
          id="v-pills-innovationDriven-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-innovationDriven"
          type="button"
          role="tab"
          aria-controls="v-pills-innovationDriven"
          aria-selected="false"
        >
          Innovation-driven
        </button>
      </div>
      <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
        <div
          class="tab-pane show active fade"
          id="v-pills-expertise"
          role="tabpanel"
          aria-labelledby="v-pills-expertise-tab"
        >
          <h1>Expertise</h1>
          Our team comprises certified Oracle professionals with a profound
          understanding of ERP solutions. They possess hands-on experience in
          implementing, customizing, and supporting Oracle ERP systems, ensuring
          that our clients receive expert guidance throughout their ERP journey.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-tailoredSolutions"
          role="tabpanel"
          aria-labelledby="v-pills-tailoredSolutions-tab"
        >
          <h1>Tailored Solutions</h1>
          We understand that each business is unique, and there is no
          one-size-fits-all solution. Therefore, we take the time to understand
          your specific needs, challenges, and goals to design customized Oracle
          ERP solutions that align perfectly with your organization.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-seamlessImplementation"
          role="tabpanel"
          aria-labelledby="v-pills-seamlessImplementation-tab"
        >
          <h1>Seamless Implementation</h1>
          A successful ERP implementation is crucial to reap its full benefits.
          With our meticulous approach and well-defined implementation
          strategies, we ensure that the transition is smooth, minimizing
          disruptions and maximizing productivity.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-ongoingSupport"
          role="tabpanel"
          aria-labelledby="v-pills-ongoingSupport-tab"
        >
          <h1>Ongoing Support</h1>
          Our commitment to your success doesn't end with implementation. We
          offer comprehensive post-implementation support and maintenance
          services, ensuring that your Oracle ERP system continues to operate
          efficiently and effectively.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-innovationDriven"
          role="tabpanel"
          aria-labelledby="v-pills-innovationDriven-tab"
        >
          <h1>Innovation-driven</h1>
          The world of ERP is constantly evolving, and we stay at the forefront
          of the latest trends and technologies. We bring the latest innovations
          to your organization, helping you adapt to the changing business
          landscape and gain a competitive edge.
        </div>
      </div>
    </div>
    <div v-else class="whyChoseMobile">
      <div>
        <h1>Expertise</h1>
        <p>
          Our team comprises certified Oracle professionals with a profound
          understanding of ERP solutions. They possess hands-on experience in
          implementing, customizing, and supporting Oracle ERP systems, ensuring
          that our clients receive expert guidance throughout their ERP journey.
        </p>
      </div>
      <div>
        <h1>Tailored Solutions</h1>
        <p>
          We understand that each business is unique, and there is no
          one-size-fits-all solution. Therefore, we take the time to understand
          your specific needs, challenges, and goals to design customized Oracle
          ERP solutions that align perfectly with your organization.
        </p>
      </div>
      <div>
        <h1>Seamless Implementation</h1>
        <p>
          A successful ERP implementation is crucial to reap its full benefits.
          With our meticulous approach and well-defined implementation
          strategies, we ensure that the transition is smooth, minimizing
          disruptions and maximizing productivity.
        </p>
      </div>
      <div>
        <h1>Ongoing Support</h1>
        <p>
          Our commitment to your success doesn't end with implementation. We
          offer comprehensive post-implementation support and maintenance
          services, ensuring that your Oracle ERP system continues to operate
          efficiently and effectively.
        </p>
      </div>
      <div>
        <h1>Innovation-driven</h1>
        <p>
          The world of ERP is constantly evolving, and we stay at the forefront
          of the latest trends and technologies. We bring the latest innovations
          to your organization, helping you adapt to the changing business
          landscape and gain a competitive edge.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./whyChoseUs.js"></script>
<style src="./whyChoseUs.css"></style>
