<template>
  <div class="oracleImplementtionSecond">
    <h1>Oracle EBS Implementation</h1>
    <div>
      <p>
        You’re the expert in your business. You understand your processes and
        how they work. And you also know your company goals. Finding a
        technology platform and business partner to support those goals isn’t as
        clear. It’s not always obvious how you can use technology best practices
        to improve the
        <span class="boldText">
          efficiency and productivity of your business.
        </span>
        <br />
        <br />
        <span class="boldTextColoured"> Oracle E-Business Suite (EBS) </span>
        provides global, integrated business applications that drive business
        performance. Whether you’re new to Oracle applications or have an older
        EBS installation you would like to upgrade, the functionality and
        support available in the newer versions of Oracle EBS applications can
        help you achieve your <span class="boldText"> business goals.</span>
        <br />
        <br />
        Yet implementing a new platform can be challenging. It might be
        necessary to re-design business processes to leverage application
        functionality. There could be a need to locate and hire permanent staff.
        Or, you might want to train current employees, who may be unable to
        complete implementation tasks due to existing responsibilities.
        <br /><br />
        CCS combines
        <span class="boldTextColoured">
          technical and industry-leading practices
        </span>
        to deliver successful business solutions and enables
        <span class="boldText"> maximized return on investments </span> for your
        Oracle applications. <br /><br />
        Leveraging its extensive experience across the various Oracle
        applications and rich domain expertise CCS enables you to use Oracle
        applications for your business advantage with robust tools,
        methodologies, templates, and accelerators and a supported center of
        excellence. <br /><br />
        Our experts implement EBS functionality
        <span class="boldTextColoured">
          to improve and support your current and future business
          processes.</span
        >
      </p>
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
