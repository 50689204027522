import { createApp } from 'vue'
import App from './App.vue'
// import Vue from 'vue';
import Carousel3d from 'vue-carousel-3d';


import "bootstrap/dist/css/bootstrap.min.css"
import router from './router.js';
// import Vue from 'vue';
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';

// Vue.use('l-map', LMap);
// Vue.use('l-tile-layer', LTileLayer);
// Vue.use('l-marker', LMarker);


createApp(App).use(router).use(Carousel3d).use('l-map', LMap).use('l-tile-layer', LTileLayer).use('l-marker', LMarker).mount('#app')


import "bootstrap/dist/js/bootstrap.js"
