<template>
    <div class="for-observe-me">
  <div class="whyChoseUs">
    <h1 class="whyChoseHeading">Our EBS Services</h1>
    <div v-if="isLaptop" class="d-flex align-items-start">
      <div
        class="nav flex-column nav-pills me-3 for-observe-me"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link active tabs for-observe-me2"
          id="v-pills-expertise-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-expertise"
          type="button"
          role="tab"
          aria-controls="v-pills-expertise"
          aria-selected="true"
        >
          Implementation and Upgrades
        </button>
        <button
          class="nav-link for-observe-me2"
          id="v-pills-tailoredSolutions-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-tailoredSolutions"
          type="button"
          role="tab"
          aria-controls="v-pills-tailoredSolutions"
          aria-selected="false"
        >
          Customization and Integration
        </button>
        <button
          class="nav-link for-observe-me2"
          id="v-pills-seamlessImplementation-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-seamlessImplementation"
          type="button"
          role="tab"
          aria-controls="v-pills-seamlessImplementation"
          aria-selected="false"
        >
          Business Process Optimization
        </button>
        <button
          class="nav-link for-observe-me2"
          id="v-pills-ongoingSupport-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-ongoingSupport"
          type="button"
          role="tab"
          aria-controls="v-pills-ongoingSupport"
          aria-selected="false"
        >
          Training and Support
        </button>
      </div>
      <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
        <div
          class="tab-pane show active fade"
          id="v-pills-expertise"
          role="tabpanel"
          aria-labelledby="v-pills-expertise-tab"
        >
          <h1>Implementation and Upgrades</h1>
          Whether you are looking to implement Oracle EBS for the first time or
          upgrade to the latest version, our experts ensure a smooth and
          efficient process that aligns with your business goals.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-tailoredSolutions"
          role="tabpanel"
          aria-labelledby="v-pills-tailoredSolutions-tab"
        >
          <h1>Customization and Integration</h1>
          We understand that no two businesses are alike. Our consultants work
          closely with your team to customize Oracle EBS to fit your specific
          needs, integrating it seamlessly with your existing systems.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-seamlessImplementation"
          role="tabpanel"
          aria-labelledby="v-pills-seamlessImplementation-tab"
        >
          <h1>Business Process Optimization</h1>
          Unlock the full potential of Oracle EBS with our business process
          optimization services. We identify areas for improvement, streamline
          workflows, and enhance productivity.
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-ongoingSupport"
          role="tabpanel"
          aria-labelledby="v-pills-ongoingSupport-tab"
        >
          <h1>Training and Support</h1>
          To empower your team, we provide comprehensive training on Oracle EBS
          and offer ongoing support to ensure that you get the most out of your
          investment
        </div>
      </div>
    </div>
    <div v-else class="whyChoseMobile">
      <div>
        <h1>Implementation and Upgrades</h1>
        <p>
          Whether you are looking to implement Oracle EBS for the first time or
          upgrade to the latest version, our experts ensure a smooth and
          efficient process that aligns with your business goals.
        </p>
      </div>
      <div>
        <h1>Customization and Integration</h1>
        <p>
          We understand that no two businesses are alike. Our consultants work
          closely with your team to customize Oracle EBS to fit your specific
          needs, integrating it seamlessly with your existing systems.
        </p>
      </div>
      <div>
        <h1>Business Process Optimization</h1>
        <p>
          Unlock the full potential of Oracle EBS with our business process
          optimization services. We identify areas for improvement, streamline
          workflows, and enhance productivity.
        </p>
      </div>
      <div>
        <h1>Training and Support</h1>
        <p>
          To empower your team, we provide comprehensive training on Oracle EBS
          and offer ongoing support to ensure that you get the most out of your
          investment
        </p>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
