<template>
  <div>
    <div class="manufacturingFifthOne">
      <h1 class="manufacturingThirdHeading">
        Oracle Fusion Discrete Manufacturing
      </h1>
      <p class="manufacturingFourthText">
        Oracle Fusion Applications represent a modern suite of business
        applications built on a modern architecture. Within the Fusion
        Applications suite, the Discrete Manufacturing module addresses the
        requirements of industries involved in the assembly of distinct items.
        Key features of Oracle Fusion Discrete Manufacturing include:
      </p>
    </div>
    <div class="whyChoseUs">
      <div v-if="isLaptop" class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-expertise-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-expertise"
            type="button"
            role="tab"
            aria-controls="v-pills-expertise"
            aria-selected="true"
          >
            BOM and Work Order Management
          </button>
          <button
            class="nav-link"
            id="v-pills-tailoredSolutions-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-tailoredSolutions"
            type="button"
            role="tab"
            aria-controls="v-pills-tailoredSolutions"
            aria-selected="false"
          >
            Production Execution
          </button>
          <button
            class="nav-link"
            id="v-pills-seamlessImplementation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-seamlessImplementation"
            type="button"
            role="tab"
            aria-controls="v-pills-seamlessImplementation"
            aria-selected="false"
          >
            Production Planning and Scheduling
          </button>
          <button
            class="nav-link"
            id="v-pills-customization-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-customization"
            type="button"
            role="tab"
            aria-controls="v-pills-customization"
            aria-selected="false"
          >
            Quality Control
          </button>
          <button
            class="nav-link"
            id="v-pills-integrations-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-integrations"
            type="button"
            role="tab"
            aria-controls="v-pills-integrations"
            aria-selected="false"
          >
            Inventory and Supply Chain Management
          </button>
          <button
            class="nav-link"
            id="v-pills-analytics-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-analytics"
            type="button"
            role="tab"
            aria-controls="v-pills-analytics"
            aria-selected="false"
          >
            Analytics and Reporting
          </button>
        </div>
        <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-expertise"
            role="tabpanel"
            aria-labelledby="v-pills-expertise-tab"
          >
            <h1>BOM and Work Order Management</h1>
            Fusion Discrete Manufacturing offers comprehensive BOM and work
            order management capabilities. It supports the creation, management,
            and visualization of complex BOM structures and work orders.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tailoredSolutions"
            role="tabpanel"
            aria-labelledby="v-pills-tailoredSolutions-tab"
          >
            <h1>Production Execution</h1>
            The module facilitates the execution of production tasks by
            providing tools to manage work orders, allocate resources, and track
            production progress in real-time.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-seamlessImplementation"
            role="tabpanel"
            aria-labelledby="v-pills-seamlessImplementation-tab"
          >
            <h1>Production Planning and Scheduling</h1>
            Fusion Discrete Manufacturing includes advanced planning and
            scheduling features to optimize production sequences, resource
            utilization, and minimize production lead times.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-customization"
            role="tabpanel"
            aria-labelledby="v-pills-customization-tab"
          >
            <h1>Quality Control</h1>
            The solution supports quality control processes by enabling
            organizations to define quality standards, perform inspections, and
            manage deviations or non-conformances.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-integrations"
            role="tabpanel"
            aria-labelledby="v-pills-integrations-tab"
          >
            <h1>Inventory and Supply Chain Management</h1>
            Fusion Discrete Manufacturing helps optimize inventory levels,
            streamline material movements, and improve visibility across the
            supply chain.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-analytics"
            role="tabpanel"
            aria-labelledby="v-pills-analytics-tab"
          >
            <h1>Analytics and Reporting</h1>
            The module provides analytics and reporting capabilities, enabling
            organizations to gain insights into production performance, resource
            utilization, and quality metrics.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>BOM and Work Order Management</h1>
          <p>
            Fusion Discrete Manufacturing offers comprehensive BOM and work
            order management capabilities. It supports the creation, management,
            and visualization of complex BOM structures and work orders.
          </p>
        </div>
        <div>
          <h1>Production Execution</h1>
          <p>
            The module facilitates the execution of production tasks by
            providing tools to manage work orders, allocate resources, and track
            production progress in real-time.
          </p>
        </div>
        <div>
          <h1>Production Planning and Scheduling</h1>
          <p>
            Fusion Discrete Manufacturing includes advanced planning and
            scheduling features to optimize production sequences, resource
            utilization, and minimize production lead times.
          </p>
        </div>
        <div>
          <h1>Quality Control</h1>
          <p>
            The solution supports quality control processes by enabling
            organizations to define quality standards, perform inspections, and
            manage deviations or non-conformances.
          </p>
        </div>
        <div>
          <h1>Inventory and Supply Chain Management</h1>
          <p>
            Fusion Discrete Manufacturing helps optimize inventory levels,
            streamline material movements, and improve visibility across the
            supply chain.
          </p>
        </div>
        <div>
          <h1>Analytics and Reporting</h1>
          <p>
            The module provides analytics and reporting capabilities, enabling
            organizations to gain insights into production performance, resource
            utilization, and quality metrics.
          </p>
        </div>
      </div>
    </div>
      <p class="manufacturingFourthTextTwo">
        When choosing between Oracle EBS Discrete Manufacturing and Oracle
        Fusion Discrete Manufacturing, organizations should consider factors
        such as their existing technology landscape, scalability needs, and
        specific industry requirements. Consulting with Oracle representatives
        or implementation partners can help determine which solution best aligns
        with the organization's goals and operational needs.
      </p>
  </div>
</template>

<script src="./sixth.js"></script>
<style src="./sixth.css"></style>
