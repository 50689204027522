<template>
  <div class="manufacturingThird mt-4">
    <div class="bankingThird">
      <div class="managedServicesFourthFirst">
        <div>
          <div>
            <h1>{{ heading }}</h1>
            {{ textContent }}
          </div>
        </div>
        <div>
          <div
          :class="heading === 'Implementation and Integration' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('i&i')"
          >
            Implementation and Integration
          </div>
          <div
          :class="heading === 'Customization' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('cust')"
          >
            Customization
          </div>
          <div
          :class="heading === 'Data Management and Analytics' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('dma')"
          >
            Data Management and Analytics
          </div>
          <div
          :class="heading === 'Regulatory Compliance' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('rc')"
          >
            Regulatory Compliance
          </div>
          <div
          :class="heading === 'Risk Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('rm')"
          >
            Risk Management
          </div>
          <div
          :class="heading === 'Digital Transformation' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('dt')"
          >
            Digital Transformation
          </div>
          <div
          :class="heading === 'Customer Experience Enhancement' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('cee')"
          >
            Customer Experience Enhancement
          </div>
          <div
          :class="heading === 'Training and Support' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('ts')"
          >
            Training and Support
          </div>
          <div
          :class="heading === 'Upgrade and Maintenance' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('um')"
          >
            Upgrade and Maintenance
          </div>
          <div
          :class="heading === 'Innovation' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('inn')"
          >
            Innovation
          </div>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      Our consulting services contribute to the overall efficiency, growth, and
      success of the banking and financial institutions you serve. By leveraging
      Oracle's powerful tools and our expertise, we help these institutions stay
      ahead in a rapidly changing landscape while maintaining the highest
      standards of security and compliance.
    </p>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
