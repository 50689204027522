export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Implementation and Integration",
      textContent:
        "Helping banks and financial institutions implement Oracle Banking solutions smoothly and integrate them with existing systems. This might involve data migration, ensuring interoperability, and minimizing disruptions during the transition.",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "i&i") {
        this.heading = "Implementation and Integration";
        this.textContent =
          "Helping banks and financial institutions implement Oracle Banking solutions smoothly and integrate them with existing systems. This might involve data migration, ensuring interoperability, and minimizing disruptions during the transition.";
      } else if (text === "cust") {
        this.heading = "Customization";
        this.textContent =
          "Tailoring Oracle solutions to match the unique requirements and processes of each client. Customization can improve efficiency and help institutions offer specialized products and services.";
      } else if (text === "dma") {
        this.heading = "Data Management and Analytics";
        this.textContent =
          "Assisting clients in leveraging Oracle's capabilities to manage and analyze large volumes of financial data. This could involve data warehousing, business intelligence, and reporting solutions to gain insights for strategic decision-making.";
      } else if (text === "rc") {
        this.heading = "Regulatory Compliance";
        this.textContent =
          "Ensuring that the Oracle solutions implemented meet the regulatory standards specific to the banking and financial sectors. This includes data security, privacy, and compliance with industry regulations.";
      } else if (text === "rm") {
        this.heading = "Risk Management";
        this.textContent =
          "Implementing Oracle solutions that enable banks to effectively manage and mitigate various types of risks, including credit risk, operational risk, and market risk.";
      } else if (text === "dt") {
        this.heading = "Digital Transformation";
        this.textContent =
          "Guiding clients through their digital transformation journeys, helping them adopt modern technologies like cloud computing, AI-driven analytics, and mobile solutions to enhance customer experiences and streamline operations.";
      } else if (text === "cee") {
        this.heading = "Customer Experience Enhancement";
        this.textContent =
          "Leveraging Oracle solutions to create seamless and personalized experiences for bank customers, whether through online banking platforms, mobile apps, or customer relationship management (CRM) systems.";
      } else if (text === "ts") {
        this.heading = "Training and Support";
        this.textContent =
          "Providing training to bank staff on how to use Oracle solutions effectively and offering ongoing technical support to ensure smooth operations.";
      } else if (text === "um") {
        this.heading = "Upgrade and Maintenance";
        this.textContent =
          "Assisting clients in upgrading their Oracle solutions to the latest versions and maintaining the systems to ensure optimal performance and security.";
      } else if (text === "inn") {
        this.heading = "Innovation";
        this.textContent =
          "Staying updated with the latest developments in Oracle technologies and financial industry trends to propose innovative solutions that can drive competitive advantage for your clients.";
      }
    },
  },
};
