import profile from "./profile";
import whatWeDo from "./whatWeDo";
import carousel from "./carousel";
import partner from "./Partner"
import testimonials from "./testimonials";
import letsConnect from "./letsConnect";
import intro from "./intro";
import faqs from "./faqs";
import solutions from "./solutions";
import industries from "./industries";


export default {
    name: 'home',
    data() {
      return {
        showHideLetsConnectModal: false
      }
    },
    components: {
      intro,
        profile,
        whatWeDo,
        carousel,
        partner,
        testimonials,
        letsConnect,
        faqs,
        solutions,
        industries
    },
    methods: {
    }
}