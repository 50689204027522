<template>
    <div>
        <first />
        <second />
        <third />
        <fourth />
        <fifth />
        <sixth />
        <seventh />
    </div>
</template>

<script src="./gst.js"></script>
<style src="./gst.css"></style>