export default {
    name: 'letsConnect',
    data() {
        return {
            showAlert: false,
            mandateAlert:false,
            ConsultationName:null,
            ConsultationEmail:null,
            ConsultationOrg:null,
            ConsultationJobTitle:null,
            ConsultationNumber:null,
            ConsultationCompName:null,
            value: '0-50'
        }
    },
    mounted() {

    },
    methods: {
        submitDetails() {
            if(this.ConsultationName && this.ConsultationEmail && this.ConsultationNumber){
                this.showAlert = true;
                setTimeout(() => {
                    this.showAlert = false;
                  }, 3000);
            }else{
                this.mandateAlert=true;
                setTimeout(() => {
                    this.mandateAlert = false;
                  }, 3000);
            }
         
        }
    }
}