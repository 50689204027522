<template>
  <div class="oracle">
    <div>
      <div class="oraclePart" :class="{ active: animate }">
        <div class="oraclePartHeader">
          <img
            src="../../../../assets/aboutUs/partners/oracle-partners.jpg"
            alt="oraclePartHeader"
            id="oraclePartHeader"
          />
        </div>
        <div class="oracle-top-left">
          <h1 class="oracle-drop-from-top">Oracle</h1>
          <p class="oracle-drop-from-bottom">
            Together Towards a Brighter Future
          </p>
        </div>
      </div>
      <div>
        <!-- <h1 class="oraclePartnerHeading">Oracle <span style="color: #f38d26">Partner</span></h1> -->
        <h1 class="oraclePartnerHeading orcPart-observe-me">
          <img
            src="https://www.evosysglobal.com/sites/default/files/2020_oracle_logo.svg"
            alt="oraclePartner"
            class="oraclePartner"
          />
        </h1>

        <div class="oraclePartnerSection">
          <div class="oraclePartnerText orcPart-observe-me">
            <p>
              We are proud to be an esteemed Oracle Partner, offering
              cutting-edge solutions and unrivaled expertise to drive your
              business forward. As a trusted Oracle partner, we have the unique
              advantage of harnessing the full potential of Oracle's
              comprehensive suite of products and services to meet your
              organization's specific needs. Whether you're a small business, a
              large enterprise, or a public sector organization, we have the
              expertise and solutions to help you achieve your objectives.
            </p>
          </div>
          <div class="orcPart-observe-me2">
            <img
              src="../../../../assets/aboutUs/partners/oracle_partner.png"
              alt=""
            />
          </div>
        </div>
        <div>
          <oracleOurPartner></oracleOurPartner>
        </div>
      </div>
      <div>
        <h1 class="oraclePartnerStoryHeading">Our Services</h1>
        <oracleServices></oracleServices>
      </div>
    </div>
  </div>
</template>

<script src="./oraclePartner"></script>
<style src="./oraclePartner.css"></style>
