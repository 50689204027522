import PartnerLogo from "./PartnerLogo";

export default {
  name: "Partner",
  components: {
    PartnerLogo,
  },
  data() {
    return {
      imageNames: [
        "delhivery",
        "vatika",
        "oyo",
        "iffco-tokio",
        "iffco",
        "m3m",
        "ansalAPI",
        "sis",
        "indus",
        "revent",
        "accenture",
        "airtel",
        "anchor",
        "expersion",
        "gannon",
        "marathon",
        "masco",
        "msl",
        "omaxe",
        "policybazaar",
        "pwc",
        "rblBank",
        "sandhar",
        "snapdeal",
        "uflex",
        "winsome",
      ],
    };
  },
};
