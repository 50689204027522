export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Recipe and Formula Management",
      textContent:
        "EBS Process Manufacturing supports the creation and management of complex recipes and formulas. It enables businesses to define the precise composition of products, including raw materials, intermediates, and processing steps.",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "odm") {
        this.heading = "Recipe and Formula Management";
        this.textContent =
          "EBS Process Manufacturing supports the creation and management of complex recipes and formulas. It enables businesses to define the precise composition of products, including raw materials, intermediates, and processing steps.";
      } else if (text === "oas") {
        this.heading = "Batch Processing";
        this.textContent =
          "The module provides tools to manage batch production processes, including scheduling, execution, and tracking of batches. It facilitates the monitoring of critical parameters and adherence to production specifications.";
      } else if (text === "ocm") {
        this.heading = "Quality Control";
        this.textContent =
          "EBS Process Manufacturing includes features for quality control and testing throughout the production lifecycle. It helps ensure that products meet established quality standards and compliance requirements.";
      } else if (text === "pto") {
        this.heading = "Lot and Serial Tracking";
        this.textContent =
          "The solution offers lot and serial tracking capabilities, allowing businesses to trace ingredients and products throughout the manufacturing process. This is essential for compliance, recall management, and maintaining product integrity.";
      } else if (text === "sac") {
        this.heading = "Inventory Management";
        this.textContent =
          "EBS Process Manufacturing assists in managing inventory levels, tracking materials, and optimizing resource utilization. It provides real-time visibility into inventory status and availability.";
      } else if (text === "rc") {
        this.heading = "Regulatory Compliance";
        this.textContent =
          "The module supports compliance with industry regulations, such as Good Manufacturing Practices (GMP), FDA requirements, and other standards specific to process manufacturing industries.";
      }
    },
  },
};
