<template>
  <div class="oracleImplementationThirdContainer">
  <div class="oracleImplementationThird">
    <p>
      CCS provides full lifecycle implementation and support services for core
      EBS application modules, including:
    </p>
    <div class="oracleImplementationThirdBox">
      <div>
        <img src="../../../../assets/oracleImplementation/finance.jpg" alt="">
        <p>Financial</p>
      </div>
      <div>
        <p>Supply Chain Management</p>
        <img src="../../../../assets/oracleImplementation/supply.jpg" alt="">
      </div>
      <div>
        <img src="../../../../assets/oracleImplementation/distribution.jpg" alt="">
        <p>Distribution</p>
      </div>
      <div>
        <p>Human Resource Management Systems</p>
        <img src="../../../../assets/oracleImplementation/humanResource.jpg" alt="">
      </div>
      <div>
        <img src="../../../../assets/oracleImplementation/crm.jpg" alt="">
        <p>Customer Relationship Management</p>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
