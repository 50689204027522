<template>
  <div class="manufacturingSecond">
    <div>
      <p>
        We provide specialized consulting services focused on Oracle solutions
        for the banking and financial sectors. This is a crucial role, as the
        banking and financial industries rely heavily on technology to
        streamline operations, enhance customer experiences, manage risk, and
        ensure compliance. CCS provides a range of solutions that cater to
        the specific needs of these industries, and our expertise in
        implementing and optimizing these solutions can bring significant value
        to your clients.
      </p>
    </div>
    <div>
      <img src="../../../../assets/banking/bankingSecond.jpg" alt="" />
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
