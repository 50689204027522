<template>
    <div class="managedServicesFourth">
      <div class="managedServicesFourthFirst">
        <div>
            <div>
                <h1>{{ heading }}</h1>
                {{ textContent }}</div>
        </div>
        <div>
            <div :class="selectedTab === 'Oracle Database Management' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('odm')">Oracle Database Management</div>
            <div :class="selectedTab === 'Oracle Application Support' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('oas')">Oracle Application Support</div>
            <div :class="selectedTab === 'Oracle Cloud Management' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('ocm')">Oracle Cloud Management</div>
            <div :class="selectedTab === 'Performance Tuning and Optimization' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('pto')">Performance Tuning and Optimization</div>
            <div :class="selectedTab === 'Security and Compliance' ? 'selectedTab' : 'unselectedTab'" @click="selectedHeading('sac')">Security and Compliance</div>
        </div>
      </div>
    </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
