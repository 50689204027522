<template>
  <div class="manufacturingFifth">
    <div class="manufacturingFifthOne">
      <h1 class="manufacturingThirdHeading">
        Oracle E-Business Suite (EBS) Discrete Manufacturing
      </h1>
      <p class="manufacturingFourthText">
        Oracle EBS is a suite of integrated applications designed to help
        organizations manage various aspects of their operations. The Discrete
        Manufacturing module within EBS is tailored for industries that involve
        the production of distinct items, such as automotive, electronics,
        aerospace, and consumer goods. Key features of Oracle EBS Discrete
        Manufacturing include:
      </p>
    </div>
    <div class="manufacturingFifthTwo">
      <div>
        <img src="../../../../assets/manufacturing/one.png" alt="">
        <h1>Bill of Materials (BOM) Management</h1>
        <p>
          EBS Discrete Manufacturing supports the creation and management of
          Bill of Materials (BOM), which defines the structure and components
          required to assemble a product. It helps manage product variations and
          configurations
        </p>
      </div>
      <div>
        <img src="../../../../assets/manufacturing/two.png" alt="">
        <h1>Work Order Management</h1>
        <p>
          The module provides tools for creating and managing work orders, which
          detail the production tasks, schedules, and resource requirements. It
          facilitates the planning and execution of manufacturing processes.
        </p>
      </div>
      <div>
        <img src="../../../../assets/manufacturing/three.png" alt="">
        <h1>Production Scheduling</h1>
        <p>
          EBS Discrete Manufacturing offers production scheduling capabilities
          to optimize resource allocation and prioritize manufacturing
          activities. It helps organizations meet delivery timelines and improve
          production efficiency.
        </p>
      </div>
      <div>
        <img src="../../../../assets/manufacturing/four.png" alt="">
        <h1>Shop Floor Control</h1>
        <p>
          The solution supports shop floor operations by providing real-time
          visibility into production progress, tracking work orders, and
          capturing data related to manufacturing processes
        </p>
      </div>
      <div>
        <img src="../../../../assets/manufacturing/five.png" alt="">
        <h1>Quality Management</h1>
        <p>
          EBS Discrete Manufacturing includes quality management features that
          enable organizations to define and enforce quality standards, perform
          inspections, and manage non-conformance issues.
        </p>
      </div>
      <div>
        <img src="../../../../assets/manufacturing/six.png" alt="">
        <h1>Inventory Management</h1>
        <p>
          The module assists in managing inventory levels, tracking components,
          and optimizing stock levels. It supports inventory accuracy and
          efficient material usage.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./fifth.js"></script>
<style src="./fifth.css"></style>
