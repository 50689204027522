export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Seamless Integration of Processes",
      textContent:
        "Logistics companies manage a multitude of interconnected processes, from procurement and inventory management to order fulfillment and transportation. Our Oracle ERP solutions provide a unified platform that seamlessly integrates these processes, enabling logistics companies to achieve greater operational efficiency, reduce manual errors, and improve collaboration across departments.",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "sip") {
        this.heading = "Seamless Integration of Processes";
        this.textContent =
          "Logistics companies manage a multitude of interconnected processes, from procurement and inventory management to order fulfillment and transportation. Our Oracle ERP solutions provide a unified platform that seamlessly integrates these processes, enabling logistics companies to achieve greater operational efficiency, reduce manual errors, and improve collaboration across departments.";
      } else if (text === "scvt") {
        this.heading = "Supply Chain Visibility and Tracking";
        this.textContent =
          "Real-time visibility into the movement of goods is essential for effective logistics management. Our Oracle ERP solutions offer robust tracking and monitoring capabilities, allowing logistics companies to track shipments, monitor inventory levels, and optimize routes. This leads to improved delivery accuracy, reduced transit times, and enhanced customer satisfaction.";
      } else if (text === "wmo") {
        this.heading = " Warehouse Management and Optimization";
        this.textContent =
          "Efficient warehouse operations are critical to the success of logistics companies. Our Oracle ERP solutions help optimize warehouse management by automating tasks such as inventory tracking, order processing, and picking. By optimizing warehouse processes, you enable logistics companies to reduce costs, minimize stockouts, and improve overall order fulfillment efficiency.";
      } else if (text === "dfp") {
        this.heading = "Demand Forecasting and Planning";
        this.textContent =
          "Accurate demand forecasting is essential for managing inventory levels and optimizing resources. Connaissant's ERP solutions leverage advanced analytics to predict demand patterns based on historical data and market trends. By providing insights into future demand, you assist logistics companies in making informed decisions to meet customer needs effectively.";
      } else if (text === "rotm") {
        this.heading = "Route Optimization and Transportation Management";
        this.textContent =
          "Efficient transportation is at the heart of the logistics industry. Our Oracle ERP solutions offer features for route optimization, carrier collaboration, and real-time tracking. By optimizing routes and managing transportation processes, logistics companies can reduce fuel costs, improve delivery times, and enhance overall supply chain efficiency.";
      } else if (text === "cra") {
        this.heading = "Compliance and Regulatory Adherence";
        this.textContent =
          "The logistics industry is subject to various regulations and compliance standards. Our Oracle ERP solutions help logistics companies navigate these complexities by automating compliance processes, managing documentation, and ensuring adherence to industry regulations. This minimizes the risk of regulatory non-compliance and associated penalties.";
      } else if (text === "ddm") {
        this.heading = "Data-driven Decision Making: ";
        this.textContent =
          "In the data-rich logistics environment, making informed decisions is critical. Our Oracle ERP solutions provide advanced reporting and analytics tools that enable logistics companies to extract valuable insights from their data. By analyzing key performance indicators and trends, you empower logistics leaders to make data-driven decisions that optimize operations and drive growth.";
      } else if (text === "csi") {
        this.heading = "Cloud-based Scalability and Innovation";
        this.textContent =
          "Logistics companies require scalable solutions that can adapt to changing business needs. Our Oracle cloud-based ERP solutions offer scalability and flexibility, enabling logistics companies to scale resources based on demand. Our partnership with Oracle also allows us to leverage emerging technologies such as AI and machine learning to bring innovation to logistics operations.";
      }
    },
  },
};
