<template>
  <div>
    <div class="whyChoseUs">
      <h1 class="whyChoseHeading">
        Our Approach to Web Application Development:
      </h1>
      <div v-if="isLaptop" class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-expertise-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-expertise"
            type="button"
            role="tab"
            aria-controls="v-pills-expertise"
            aria-selected="true"
          >
            Strategic Consultation
          </button>
          <button
            class="nav-link"
            id="v-pills-tailoredSolutions-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-tailoredSolutions"
            type="button"
            role="tab"
            aria-controls="v-pills-tailoredSolutions"
            aria-selected="false"
          >
            User-Centric Design
          </button>
          <button
            class="nav-link"
            id="v-pills-seamlessImplementation-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-seamlessImplementation"
            type="button"
            role="tab"
            aria-controls="v-pills-seamlessImplementation"
            aria-selected="false"
          >
            Scalability and Performance
          </button>
          <button
            class="nav-link"
            id="v-pills-ongoingSupport-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-ongoingSupport"
            type="button"
            role="tab"
            aria-controls="v-pills-ongoingSupport"
            aria-selected="false"
          >
            Cutting-Edge Technologies
          </button>
          <button
            class="nav-link"
            id="v-pills-enhanced-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-enhanced"
            type="button"
            role="tab"
            aria-controls="v-pills-enhanced"
            aria-selected="false"
          >
            Security Measures
          </button>
          <button
            class="nav-link"
            id="v-pills-access-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-access"
            type="button"
            role="tab"
            aria-controls="v-pills-access"
            aria-selected="false"
          >
            Access to Latest Oracle Updates
          </button>
        </div>
        <div class="tab-content whyChoseUsText" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-expertise"
            role="tabpanel"
            aria-labelledby="v-pills-expertise-tab"
          >
            <h1>Strategic Consultation</h1>
            We begin by understanding your business goals, target audience, and
            unique requirements. Our team collaborates closely with you to
            develop a strategic plan that forms the foundation for your custom
            web application.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tailoredSolutions"
            role="tabpanel"
            aria-labelledby="v-pills-tailoredSolutions-tab"
          >
            <h1>User-Centric Design</h1>
            A seamless user experience is paramount. Our expert designers create
            intuitive, visually appealing interfaces that not only captivate
            users but also enhance overall usability.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-seamlessImplementation"
            role="tabpanel"
            aria-labelledby="v-pills-seamlessImplementation-tab"
          >
            <h1>Scalability and Performance</h1>
            Our web applications are built with scalability in mind, ensuring
            they can grow seamlessly as your business expands. Performance
            optimization is a priority to deliver a swift and responsive user
            experience.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-ongoingSupport"
            role="tabpanel"
            aria-labelledby="v-pills-ongoingSupport-tab"
          >
            <h1>Cutting-Edge Technologies</h1>
            We leverage the latest technologies and frameworks to build robust
            and feature-rich web applications. Our developers stay abreast of
            industry trends, incorporating innovations that keep your
            application at the forefront.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-enhanced"
            role="tabpanel"
            aria-labelledby="v-pills-enhanced-tab"
          >
            <h1>Security Measures</h1>
            Your data's security is our priority. We implement industry best
            practices to fortify your web application against potential threats,
            ensuring a secure environment for your users.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Strategic Consultation</h1>
          <p>
            We begin by understanding your business goals, target audience, and
            unique requirements. Our team collaborates closely with you to
            develop a strategic plan that forms the foundation for your custom
            web application.
          </p>
        </div>
        <div>
          <h1>User-Centric Design</h1>
          <p>
            A seamless user experience is paramount. Our expert designers create
            intuitive, visually appealing interfaces that not only captivate
            users but also enhance overall usability.
          </p>
        </div>
        <div>
          <h1>Scalability and Performance</h1>
          <p>
            Our web applications are built with scalability in mind, ensuring
            they can grow seamlessly as your business expands. Performance
            optimization is a priority to deliver a swift and responsive user
            experience.
          </p>
        </div>
        <div>
          <h1>Cutting-Edge Technologies</h1>
          <p>
            We leverage the latest technologies and frameworks to build robust
            and feature-rich web applications. Our developers stay abreast of
            industry trends, incorporating innovations that keep your
            application at the forefront.
          </p>
        </div>
        <div>
          <h1>Security Measures</h1>
          <p>
            Your data's security is our priority. We implement industry best
            practices to fortify your web application against potential threats,
            ensuring a secure environment for your users.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
