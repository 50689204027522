<template>
  <div class="manufacturingSecond">
    <div>
      <p>
        In the ever-evolving landscape of the telecommunications industry,
        having a robust and agile ERP (Enterprise Resource Planning) system is
        crucial for streamlined operations, enhanced customer experiences, and
        staying competitive. As an Oracle partner specializing in serving the
        ERP needs of the telecom sector, we play a vital role in helping
        telecom companies navigate complex challenges and capitalize on new
        opportunities. Let's explore how our partnership with Oracle is
        transforming ERP solutions in the telecom industry.
      </p>
    </div>
    <div>
      <img
        src="../../../../assets/telecom/telecomSecond.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
