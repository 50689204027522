import first from "./first";
import second from "./second";
import third from "./third";
import fourth from "./fourth";
import fifth from "./fifth";
import sixth from "./sixth";
import seventh from "./seventh";

export default {
    name: 'gstFirst',
    components: {
        first,
        second,
        third,
        fourth,
        fifth,
        sixth,
        seventh
    }
}