<template>
    <div class="vendorPortalFirst">
        <div class="vendorIntro">
            <h1>Vendor Portal</h1>
        </div>
    </div>
</template>


<script src="./first.js"></script>
<style src="./first.css"></style>