<template>
  <div class="testimonialsPage">
    <div class="testimonialsContainer">
      <div>
        <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
      <div class="testimonialsContainerImage">
        <img src="../../../assets/testimonials/testimonials1.jpg" class="testimonialsImage" alt="">
      </div>
      <div>
       <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
    </div>
    <div class="testimonialsContainer">
      <div>
        <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
      <div class="testimonialsContainerImage">
        <img src="../../../assets/testimonials/testimonials2.jpg" class="testimonialsImage" alt="">
      </div>
      <div>
       <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
    </div>
    <div class="testimonialsContainer">
      <div>
        <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
      <div class="testimonialsContainerImage">
        <img src="../../../assets/testimonials/testimonials3.jpg" class="testimonialsImage" alt="">
      </div>
      <div>
       <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
    </div>
    <div class="testimonialsContainer">
      <div>
        <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
      <div class="testimonialsContainerImage">
        <img src="../../../assets/testimonials/testimonials4.jpg" class="testimonialsImage" alt="">
      </div>
      <div>
       <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
    </div>
    <div class="testimonialsContainer">
      <div>
        <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
      <div class="testimonialsContainerImage">
        <img src="../../../assets/testimonials/testimonials5.jpg" class="testimonialsImage" alt="">
      </div>
      <div>
       <i class="bi bi-quote"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          provident alias dolore exercitationem corrupti et nisi, ad enim quasi
          quam sint voluptatem repellendus mollitia voluptas corporis
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./testimonials.js"></script>
<style src="./testimonials.css"></style>
