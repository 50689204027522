<template>
    <div>
        <div class="about" :class="{ active: animate }">
      <div class="aboutHeader">
        <img
          src="../../../../assets/aboutUs/aboutUsHeader.jpg"
          alt="aboutHeader"
          id="aboutHeaderImage"
        />
      </div>
      <div class="top-left">
        <h1 class="drop-from-top">
            Oracle Managed Services 
        </h1>
        <p class="drop-from-bottom">
            Unlocking the Full Potential of <br />
            Your Oracle Investment
        </p>
      </div>
    </div>
    </div>
</template>

<script src="./first.js"></script>
<style src="./first.css"></style>