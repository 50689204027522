<template>
  <div class="manufacturingFourth">
    <h1 class="manufacturingThirdHeading">
      Oracle Fusion Process Manufacturing
    </h1>
    <p class="manufacturingFourthText">
      Oracle Fusion Applications represent Oracle's next-generation suite of
      business applications that are built on modern architecture. Within the
      Fusion Applications suite, the Process Manufacturing module addresses the
      needs of industries involved in batch or process manufacturing. Key
      features of Oracle Fusion Process Manufacturing include
    </p>
    <div class="careersThird">
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Formulation and Recipe Management</h1>
          <p>
            Fusion Process Manufacturing supports the formulation and management
            of complex recipes and formulas. It enables businesses to define
            precise ingredient lists, processing steps, and quality parameters.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/manufacturing/formulation.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/manufacturing/batchExecution.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Batch Execution</h1>
          <p>
            The module facilitates the execution of batch processes, including
            scheduling, execution, and tracking of batches. It allows for
            real-time monitoring of processes and quality checks.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Quality Assurance</h1>
          <p>
            Fusion Process Manufacturing includes robust quality assurance
            capabilities, enabling businesses to monitor and control product
            quality throughout the production process.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/manufacturing/qualityAssurance.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/manufacturing/traceability.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Traceability</h1>
          <p>
            The solution offers comprehensive traceability features, allowing
            businesses to track and trace ingredients, materials, and products
            across the entire supply chain.
          </p>
        </div>
      </div>
      <div class="manufacturingFourthSecondBox">
        <div>
          <h1>Analytics and Reporting</h1>
          <p>
            Fusion Process Manufacturing provides advanced analytics and
            reporting capabilities, enabling businesses to gain insights into
            production performance, resource utilization, and quality metrics.
          </p>
        </div>
        <div>
          <img
            src="../../../../assets/manufacturing/analytics.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
      </div>
      <div class="manufacturingFourthFirstBox">
        <div>
          <img
            src="../../../../assets/manufacturing/regulatoryCompliance.jpg"
            class="careersBoxImageOne"
            alt=""
          />
        </div>
        <div>
          <h1>Regulatory Compliance</h1>
          <p>
            The module helps businesses stay compliant with industry-specific
            regulations and standards, ensuring adherence to safety and quality
            requirements.
          </p>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      Both Oracle EBS Process Manufacturing and Oracle Fusion Process
      Manufacturing are designed to meet the unique needs of process
      manufacturing industries. When choosing between the two, businesses should
      consider factors such as their current technology landscape, scalability
      needs, and specific industry requirements. Please contact us to determine which
      solution aligns best with the organization's goals and operational needs.
    </p>
  </div>
</template>

<script src="./fourth.js"></script>
<style src="./fourth.css"></style>
