import first from "./first";
import second from "./second";
import third from "./third";
import fourth from "./fourth";
import applyPage from "./applyPage";

export default {
    name: 'careers',
    data() {

    },
    components: {
        first,
        second,
        third,
        fourth,
        applyPage
    }
}