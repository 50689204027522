import first from "./first";
import second from "./second";
import third from "./third";




export default {
    name: 'telecom',
    components: {
        first,
        second,
        third
    }
}