import first from "./first";
import second from "./second";
import third from "./third";
import fourth from "./fourth";
import fifth from "./fifth";

export default {
    name: 'cms',
    components: {
        first,
        second,
        third,
        fourth,
        fifth
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}