import first from "./first";
import second from "./second";
import third from "./third";
import fourth from "./fourth";
import fifth from "./fifth";
import sixth from "./sixth";


export default {
    name: 'manufacturing',
    data() {
        return {
            
        }
    },
    components: {
        first,
        second,
        third,
        fourth,
        fifth,
        sixth
    }
}