<template>
  <div class="intro">
    <div class="intro_box">
      <div id="intro_strip"></div>
      <div id="intro_content_box"></div>

      <div id="intro_content">
        <h1>Illuminating the Path to</h1>
        <h1>Transformation and Unveiling</h1>
        <h1>the Future</h1>
        <p>
          Having more than 25 Years of Oracle consulting experience is a
          significant achievement that showcases a deep understanding of
          Oracle's diverse suite of products and the ability to deliver valuable
          solutions to businesses. Over the past ten years, an Oracle consultant
          has accumulated a wealth of knowledge, expertise, and hands-on
          experience, making them a highly sought-after professional in the
          field of enterprise technology.
        </p>
      </div>
    </div>
  </div>
</template>

<style src="./intro.css"></style>
