import whyChoseUs from "./whyChoseUs";

export default {
  name: 'aboutUs',
  components: {
    whyChoseUs
  },
    data() {
      return {
        animate: false,
        observer:null
      };
    },
    mounted() {
      setTimeout(() => {
        this.animate = true;
      }, 100);

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-viewport");
            // this.observer.unobserve(entry.target)
          } else {
            entry.target.classList.remove("in-viewport");
          }
        });
      }
      );
      const hidele = document.querySelectorAll(".abt-observe-me");
      hidele.forEach((el) => this.observer.observe(el));

      const hidele1 = document.querySelectorAll(".abt-observe-me2");
      hidele1.forEach((el) => this.observer.observe(el));
    },
  };