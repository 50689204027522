<template>
  <div class="slide-track">
    <div class="slide">
      <img
        :src="require(`@/assets/partners/${imageName}.png`)"
        class="img-fluid"
        alt="partnersLogo"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "partnerLogo",
  props: ["imageName"],
  data(){
    return{

    }
  },
  mounted() {},
  methods:{

  }
};
</script>
<style>
@media (min-width: 176px) {
  .slide {
  height: auto;
  width: 80px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-right: 20px;
  border-radius: 10px;
}
}
@media (min-width: 376px) {
  .slide {
  width: 120px;
}
}
@media (min-width: 576px) {
  .slide {
    width: 150px;
  }
}

@media (min-width: 868px) {
  .slide {
    width: 180px;
  }
}

@media (min-width: 992px) {
  .slide {
    width: 200px;
  }
}

@media (min-width: 1092px) {
  .slide {
    width: 200px;
  }
}

@media (min-width: 1200px) {
  .slide {
    width: 200px;
  }
}

@media (min-width: 1400px) {
  .slide {
    width: 200px;
  }
  
}



.slide-track {
  display: flex;
  width: calc(250px * 30);
  animation: scroll 60s linear infinite;
}
img {
  width: 70%;
  height: 70%;
  border-radius: 25px;
  transition: transform 1s;
  margin-left: 20px;
}
img:hover {
  transform: translateZ(20px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 15));
  }
}
</style>
