<template>
    <div>
        <intro />
        <profile />
        <solutions />
        <whatWeDo />
        <testimonials />
        <partner/>
        <industries />
        <faqs />
        <letsConnect />
    </div>
</template>

<script src="./home.js"></script>
<style src="./home.css"></style>