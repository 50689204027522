export default {
    name: 'second',
    data() {
        return {
            observer:null
        };
      },
    mounted(){
        this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    entry.target.classList.add("in-viewport");
                    // this.observer.unobserve(entry.target)
                  } else {
                    entry.target.classList.remove("in-viewport");
                  }
                });
              }
              );
              const hidele = document.querySelectorAll(".sec-observe-me");
              hidele.forEach((el) => this.observer.observe(el));

    }
}