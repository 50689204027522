<template>
  <div class="gstSecond">
    <p>
      <span style="font-size: 22px; font-weight: bold; color: #f38d26">
        In the intricate world</span
      >
      of managing diverse collections, precision, organization, and
      accessibility are paramount. Introducing
      <span style="font-size: 22px; font-weight: bold; color: #f38d26">
        CCS Collection Management System</span
      >– a comprehensive solution designed to simplify the complexities of
      <span style="font-size: 22px; font-weight: bold; color: #f38d26">
        curation, tracking, and accessibility.</span
      >
    </p>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>

<!-- In the intricate world of managing diverse collections, precision, organization,
and accessibility are paramount. Introducing CCS Collection Management System –
a comprehensive solution designed to simplify the complexities of collection
curation, tracking, and accessibility. -->
