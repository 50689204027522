<template>
  <div class="industries">
    <h1>Industry Expertise</h1>
    <p>
      We’ve excelled our experience in a wide range of industries to bring
      valuable insights and provide our customers with the truly beneficial
      solutions
    </p>
    <div  class="industries_container">
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/banking.png" alt="">
        <p>Banking</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/finance.png" alt="">
        <p>Finance</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/insurance.png" alt="">
        <p>Insurance</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/manufacturing.png" alt="">
        <p>Manufacturing</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/infrastructure.png" alt="">
        <p>Infrastructure</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/real-estate.png" alt="">
        <p>Real Estate</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/logistics.png" alt="">
        <p>Logistics</p>
      </div>
      <div class="indus-observe-me">
        <img src="../../../../assets/home/industries/telecom.png" alt="">
        <p>Telecom</p>
      </div>
    </div>
  </div>
</template>
<script src="./industries.js">
</script>
<style src="./industries.css"></style>
