 import oracleOurPartner from "./oracleOurPartner"
 import oracleServices from "./oracleServices"
 export default {
    name:'OraclePartner',
    components:{
        oracleOurPartner,
        oracleServices
    },
    data() {
        return {
            observer:null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("in-viewport");
                // this.observer.unobserve(entry.target)
              } else {
                entry.target.classList.remove("in-viewport");
              }
            });
          }
          );
          const hidele = document.querySelectorAll(".orcPart-observe-me");
          hidele.forEach((el) => this.observer.observe(el));
    
          const hidele1 = document.querySelectorAll(".orcPart-observe-me2");
          hidele1.forEach((el) => this.observer.observe(el));
        
    },
    methods: {
        
    },
 }