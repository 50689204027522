<template>
  <div class="managingWidth">
    <h1>Why Choose CCS Collection Management System?</h1>
    <div class="fifthContainer">
      <div>
        <h2>Tailored Solutions</h2>
        <p>
          Our Collection Management System is customizable to meet the unique
          needs of different institutions, ensuring a seamless integration with
          existing processes and workflows.
        </p>
      </div>
      <div>
        <h2>User-Friendly Interface</h2>
        <p>
          With an intuitive and user-friendly interface, our system is designed
          to minimize training requirements, allowing your team to adapt quickly
          and efficiently.
        </p>
      </div>
      <div>
        <h2>Scalability</h2>
        <p>
          Built to grow with your collection, our system accommodates
          expansions, ensuring scalability as your institution evolves.
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./fifth.js"></script>
<style src="./fifth.css"></style>
