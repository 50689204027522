<template>
  <div>
    <div class="oracleOurServices">
      <!-- <h1 class="oracleServicesHeading">Our Services</h1> -->
      <div v-if="isLaptop" class="d-flex align-items-start ourServices">
        <div
          class="nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active tabs"
            id="v-pills-cloud-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-cloud"
            type="button"
            role="tab"
            aria-controls="v-pills-cloud"
            aria-selected="true"
          >
            Oracle Cloud Services
          </button>
          <button
            class="nav-link"
            id="v-pills-fusion-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-fusion"
            type="button"
            role="tab"
            aria-controls="v-pills-fusion"
            aria-selected="false"
          >
            Oracle Database Solutions
          </button>
          <button
            class="nav-link"
            id="v-pills-reporting-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-reporting"
            type="button"
            role="tab"
            aria-controls="v-pills-reporting"
            aria-selected="false"
          >
            Oracle E-Business Suite
          </button>
          <button
            class="nav-link"
            id="v-pills-integration-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-integration"
            type="button"
            role="tab"
            aria-controls="v-pills-integration"
            aria-selected="false"
          >
            Oracle Fusion Applications
          </button>
          <button
            class="nav-link"
            id="v-pills-oracle-fushion-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-fushion-application"
            type="button"
            role="tab"
            aria-controls="v-pills-fushion-application"
            aria-selected="false"
          >
            Oracle Analytics
          </button>
        </div>
        <div class="tab-content oracleServicesTex" id="v-pills-tabContent">
          <div
            class="tab-pane show active fade"
            id="v-pills-cloud"
            role="tabpanel"
            aria-labelledby="v-pills-cloud-tab"
          >
            <h1>Oracle Cloud Services</h1>
            Embrace the power of the cloud with Oracle Cloud Infrastructure
            (OCI), Oracle Cloud Applications, and Oracle Cloud Platform. We can
            help you migrate your workloads to the cloud, optimize cloud
            resources, and ensure cost-effective cloud operations.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-fusion"
            role="tabpanel"
            aria-labelledby="v-pills-fusion-tab"
          >
            <h1>Oracle Database Solutions</h1>
            Leverage the industry-leading Oracle Database to manage, store, and
            secure your critical data. Our experts can assist with database
            installation, configuration, tuning, and ongoing management.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-reporting"
            role="tabpanel"
            aria-labelledby="v-pills-reporting-tab"
          >
            <h1>Oracle E-Business Suite</h1>
            Streamline your business processes with Oracle E-Business Suite. Our
            consultants can help you implement and customize the suite to meet
            your organization's unique requirements.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-integration"
            role="tabpanel"
            aria-labelledby="v-pills-integration-tab"
          >
            <h1>Oracle Fusion Applications</h1>
            Take advantage of Oracle's next-generation applications suite. We
            can help you seamlessly integrate and optimize Oracle Fusion
            Applications for a more efficient and productive enterprise.
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-fushion-application"
            role="tabpanel"
            aria-labelledby="v-pills-oracle-fushion-tab"
          >
            <h1>Oracle Analytics</h1>
            Unlock the insights hidden within your data with Oracle Analytics.
            Our data specialists can help you design and implement analytics
            solutions to drive data-driven decision-making.
          </div>
        </div>
      </div>
      <div v-else class="whyChoseMobile">
        <div>
          <h1>Oracle Cloud Services</h1>
          <p>
            Embrace the power of the cloud with Oracle Cloud Infrastructure
            (OCI), Oracle Cloud Applications, and Oracle Cloud Platform. We can
            help you migrate your workloads to the cloud, optimize cloud
            resources, and ensure cost-effective cloud operations.
          </p>
        </div>
        <div>
          <h1>Oracle Database Solutions</h1>
          <p>
            Leverage the industry-leading Oracle Database to manage, store, and
            secure your critical data. Our experts can assist with database
            installation, configuration, tuning, and ongoing management.
          </p>
        </div>
        <div>
          <h1>Oracle E-Business Suite</h1>
          <p>
            Streamline your business processes with Oracle E-Business Suite. Our
            consultants can help you implement and customize the suite to meet
            your organization's unique requirements.
          </p>
        </div>
        <div>
          <h1>Oracle Fusion Applications</h1>
          <p>
            Take advantage of Oracle's next-generation applications suite. We
            can help you seamlessly integrate and optimize Oracle Fusion
            Applications for a more efficient and productive enterprise.
          </p>
        </div>
        <div>
          <h1>Oracle Analytics</h1>
          <p>
            Unlock the insights hidden within your data with Oracle Analytics.
            Our data specialists can help you design and implement analytics
            solutions to drive data-driven decision-making.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./oracleServices"></script>
<style src="./oracleServices.css"></style>
