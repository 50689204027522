export default {
    name: 'third',
    data() {
        return {
            observer:null
        };
      },
    mounted(){
        this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    entry.target.classList.add("in-viewport");
                    // this.observer.unobserve(entry.target)
                  } else {
                    entry.target.classList.remove("in-viewport");
                  }
                });
              }
              );
              const hidele = document.querySelectorAll(".thr-observe-me");
              hidele.forEach((el) => this.observer.observe(el));

              const hidele2 = document.querySelectorAll(".thr-observe-me2");
                hidele2.forEach((el) => this.observer.observe(el));

    }
}