<template>
  <div class="careersFirst">
    <div>
      <h1>
        Unlock Your Potential: <br />
        Join Our Team of Innovators and Visionaries
      </h1>
      <p>
        Discover your path to success with us. Join our team of forward-thinkers
        and creators who are shaping the future. At Connaissant Consultancy Services, we're more
        than a workplace we're a community driven by innovation and
        collaboration. Bring your skills and passion, and together, let's
        achieve greatness
      </p>
    </div>
    <img
      class="careersImage"
      src="../../../../assets/careers/careersImage.jpg"
      alt=""
    />
  </div>
</template>

<script src="./first.js"></script>
<style src="./first.css"></style>
