<template>
  <div>
    <div class="openKM">
      <div class="opneKMPart" :class="{ active: animate }">
        <div class="openKMPartHeader">
          <img
            src="../../../../assets/aboutUs/partners/openKM-Partners.png"
            alt="openKMPartHeader"
            id="openKMPartHeader"
          />
        </div>
        <div class="openKM-top-left">
          <h1 class="openKM-drop-from-top">OpenKM</h1>
          <p class="openKM-drop-from-bottom">
            Building Together, Succeeding Together
          </p>
        </div>
      </div>

      <div>
        <h1 class="openKMPartnerHeading">OpenKM Partner</h1>
        <div class="oraclePartnerSection">
          <div class="oraclePartnerText">
            <p>
              CCS has partnered with OpenKM, a Spanish company, to distribute
              and implement the Document Management Solution called OpenKM in
              India. OpenKM focuses on creating a Document Management, which due
              to its characteristics, can be used in both large companies and
              SMEs. It is a very useful tool for Knowledge Management, providing
              a flexible and lower-cost alternative to other proprietary
              applications.
            </p>
          </div>
          <div>
            <img
              src="../../../../assets/aboutUs/partners/openkm_partner.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <h1 class="openKMPartFeaturesHeading">Features and Benefits</h1>
        <featuresBenefits/>
      </div>
    </div>
  </div>
</template>

<script src="./openKmPartner"></script>
<style src="./openKmPartner.css"></style>
