<template>
  <div class="manufacturingSecond">
    <div>
      <p class="financialFirst">
        Oracle Financials is a comprehensive suite of applications that provide
        organizations with tools to manage their financial processes, optimize
        resource allocation, and ensure compliance. The suite includes a range
        of core modules that cover various aspects of financial management. Here
        are some of the core modules within Oracle Financials we serve
      </p>
    </div>
    <div>
      <img src="../../../../assets/financial/financialFirst.jpg" alt="" />
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
