export default {
    name: 'applyPage',
    components: {

    },
    props: ['showHideApplyModal'],
    data() {
        return {
            showHideModal: ''
        }   
    }, 
    mounted() {
        this.showHideModal = this.showHideApplyModal
    },
    methods: {

    }
}