<template>
  <div class="careersSecond">
    <h1>Experience Life at CCS - Where Your Career Thrives!</h1>
    <p>
      Welcome to a world where work is not just a place you go, but a fulfilling
      journey you embark upon every day. At CCS, we're not just
      offering jobs; we're inviting you to be part of a vibrant community that
      values innovation, collaboration, and personal growth. Discover what life
      at CCS is all about and why our team members are proud to call
      this place home.
    </p>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
