<template>
    <div>
        <first />
        <second />
        <third />
        <fourth />
        <fifth />
    </div>
</template>
<script src="./customAplications.js"></script>
<style src="./customAplications.css"></style>