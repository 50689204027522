<template>
  <div class="manufacturingThird mt-4">
    <div class="telecomThird">
      <div class="managedServicesFourthFirst">
        <div>
          <div>
            <h1>{{ heading }}</h1>
            {{ textContent }}
          </div>
        </div>
        <div>
          <div
          :class="heading === 'Comprehensive Integration' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('ci')"
          >
            Comprehensive Integration
          </div>
          <div
          :class="heading === 'Billing and Revenue Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('brm')"
          >
            Billing and Revenue Management
          </div>
          <div
          :class="heading === 'Real-time Insights and Reporting' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('rir')"
          >
            Real-time Insights and Reporting
          </div>
          <div
          :class="heading === 'Customer Relationship Management (CRM) Integration' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('crmi')"
          >
            Customer Relationship Management (CRM) Integration
          </div>
          <div
          :class="heading === 'Supply Chain Management' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('scm')"
          >
            Supply Chain Management
          </div>
          <div
          :class="heading === 'Compliance and Security' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('cs')"
          >
            Compliance and Security
          </div>
          <div
          :class="heading === 'Scalability and Cloud Integration' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('sci')"
          >
            Scalability and Cloud Integration
          </div>
          <div
          :class="heading === 'Future-Proofing Through Innovation' ? 'selectedTab' : 'unselectedTab'"
            @click="selectedHeading('fpti')"
          >
            Future-Proofing Through Innovation
          </div>
        </div>
      </div>
    </div>
    <p class="manufacturingFourthText">
      As an Oracle partner specializing in ERP solutions for the telecom
      industry, we play a pivotal role in helping telecom companies optimize
      their operations, improve customer experiences, and embrace digital
      transformation. Our expertise in integrating Oracle ERP solutions with
      the unique needs of the telecom sector ensures that companies can
      effectively manage their resources, drive growth, and stay ahead in a
      rapidly evolving industry.
    </p>
  </div>
</template>

<script src="./third.js"></script>
<style src="./third.css"></style>
