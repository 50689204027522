import first from "./first";
import second from "./second";
import third from "./third";
import fourth from "./fourth";


export default {
    name: 'Vendor Portal',
    components: {
        first,
        second,
        third,
        fourth
    },
    data() {
        return {

        }
    }
}