export default {
  name: "footer",
  data() {
    return {
      date: null,
    };
  },
  mounted() {
    const date = new Date();
    const year = date.getFullYear();
    this.date = year;
  },
  methods: {},
};
