export default {
  name: "managedServicesFourth",
  components: {},
  data() {
    return {
      heading: "Comprehensive Integration",
      textContent:
        "EBS Process Manufacturing supports the creation and management of complex recipes and formulas. It enables businesses to define the precise composition of products, including raw materials, intermediates, and processing steps.",
    };
  },
  mounted() {},
  methods: {
    selectedHeading(text) {
      if (text === "ci") {
        this.heading = "Comprehensive Integration";
        this.textContent =
          "Telecom companies operate in multifaceted environments with various departments and functions. Oracle's ERP solutions provide a unified platform that integrates diverse processes such as finance, procurement, inventory management, sales, and customer service. By centralizing data and automating workflows, you help telecom companies achieve greater efficiency and accurate information sharing across the organization.";
      } else if (text === "brm") {
        this.heading = "Billing and Revenue Management";
        this.textContent =
          "Telecom companies handle intricate billing processes involving various services and subscription models. Oracle's ERP solutions enable telecom providers to manage complex billing scenarios, from prepaid plans to postpaid contracts. This ensures accurate and timely billing, minimizing revenue leakage and improving financial control.";
      } else if (text === "rir") {
        this.heading = "Real-time Insights and Reporting";
        this.textContent =
          "In a data-driven industry like telecom, having access to real-time insights is pivotal. Oracle's ERP solutions offer robust reporting and analytics capabilities, enabling telecom companies to make informed decisions based on up-to-date information. With customizable dashboards and advanced reporting tools, you empower telecom leaders to monitor key performance indicators, track trends, and drive strategic planning.";
      } else if (text === "crmi") {
        this.heading = "Customer Relationship Management (CRM) Integration";
        this.textContent =
          "A seamless integration between ERP and CRM systems is crucial for a holistic view of customer interactions. By working closely with CCS, you facilitate the integration of ERP and CRM systems, ensuring that telecom companies have a unified customer view. This leads to enhanced customer engagement, personalized offerings, and improved customer satisfaction.";
      } else if (text === "scm") {
        this.heading = "Supply Chain Management";
        this.textContent =
          "Efficient supply chain management is pivotal for timely delivery of telecom products and services. CCS Oracle ERP solutions provide end-to-end visibility into the supply chain, enabling telecom companies to optimize inventory levels, monitor vendor performance, and ensure smooth procurement processes. This leads to minimized stockouts and improved operational efficiency.";
      } else if (text === "cs") {
        this.heading = "Compliance and Security";
        this.textContent =
          "Telecom companies operate in a regulated environment with strict compliance requirements. CCS Oracle ERP solutions help ensure adherence to industry regulations and data security standards. By implementing robust security measures and automating compliance processes, you help telecom providers safeguard sensitive data and maintain regulatory compliance.";
      } else if (text === "sci") {
        this.heading = "Scalability and Cloud Integration";
        this.textContent =
          "As telecom companies grow and adapt to changing market demands, scalability is crucial. CCS Oracle ERP solutions offer the flexibility to scale operations seamlessly, making them suitable for both small and large telecom enterprises. With cloud integration options, you provide telecom companies the agility to scale resources based on demand and access their ERP systems from anywhere.";
      } else if (text === "fpti") {
        this.heading = "Future-Proofing Through Innovation";
        this.textContent =
          "Your partnership with CCS positions you to leverage cutting-edge technologies like AI, machine learning, and automation to enhance ERP capabilities in the telecom sector. By embracing these innovations, you help telecom companies streamline processes, automate routine tasks, and uncover new business insights.";
      }
    },
  },
};
