<template>
  <div>
    <div class="about" :class="{ active: animate }">
      <div class="aboutHeader">
        <img
          src="../../../../assets/customApplications/customApplications.jpg"
          alt="aboutHeader"
          id="aboutHeaderImage"
        />
      </div>
      <div class="top-left">
        <h1 class="drop-from-top">
          Custom <br />
          Applications
        </h1>
        <p class="drop-from-bottom">
          Elevate Your Digital Presence with <br> Expert Web Application Development
        </p>
      </div>
    </div>
  </div>
</template>
<script src="./first.js"></script>
<style src="./first.css"></style>
