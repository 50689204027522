<template>
  <div class="manufacturingSecond">
    <div>
      <p>
        In the intricate and fast-paced world of the logistics industry,
        efficient operations and accurate data management are paramount. As an
        Oracle partner specializing in providing ERP (Enterprise Resource
        Planning) solutions for the logistics sector, our expertise and
        collaboration with Oracle play a pivotal role in helping logistics
        companies streamline their operations, enhance supply chain visibility,
        and achieve superior customer satisfaction. Let's delve into how our
        partnership with Oracle is shaping ERP solutions for the logistics
        industry.
      </p>
    </div>
    <div>
      <img src="../../../../assets/logistics/logisticsSecond.jpg" alt="" />
    </div>
  </div>
</template>

<script src="./second.js"></script>
<style src="./second.css"></style>
