import applyPage from "../applyPage";


export default {
    name: 'careersFourth',
    components: {
        applyPage
    },
    data() {
        return {
         showHideAplyModal: false
        }
    },
    mounted() {

    }, 
    methods: {
        openCloseLetsConnectModal() {
            this.showHideAplyModal = !this.showHideAplyModal;
        },
    }
}